import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';
  userType: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.userType = this.authService.getUserType();
   }

  getPaginatedShiftsByStatus(page, size, status,agencyId, clientId, workerId, startDate, endDate): Observable<any> {

    //  if(agencyId=="") agencyId = null;
    //  if( clientId=="") clientId = null;
    //  if( workerId=="") workerId = null;
    //  if( startDate=="") startDate = null;
    //  if( endDate=="") endDate = null;


    if(this.userType == 'admin'){
      return this.http.get(`${this.base_url}/shifts-status/${page}/${size}/${status}?clientId=${clientId}&agencyId=${agencyId}&workerId=${workerId}&startDate=${startDate}&endDate=${endDate}`);
    }
    else if(this.userType == 'agency'){
      return this.http.get(`${this.base_url}/agency-shifts-status/${this.storageService.decrypt(localStorage.getItem('agentId'))}/${page}/${size}/${status}?clientId=${clientId}&workerId=${workerId}&startDate=${startDate}&endDate=${endDate}`);
    }
    else if(this.userType == 'client'){
      return this.http.get(`${this.base_url}/shifts-clients-status/${page}/${size}/${this.storageService.decrypt(localStorage.getItem('clientId'))}/${status}?agencyId=${agencyId}&workerId=${workerId}&startDate=${startDate}&endDate=${endDate}`);
    }

  }

  getPaginatedClientShiftsByStatus(page, size, status, client): Observable<any> {

    return this.http.get(`${this.base_url}/shifts-clients-status/${page}/${size}/${client}/${status}`);

  }

  getPaginatedShiftsByWorkerIdAndStatus(workerId, agencyId,page, size ): Observable<any> {

    return this.http.get(`${this.base_url}/worker-agency-shift/billing/${workerId}/${agencyId}/${page}/${size}`);

  }

  getAgencyReleasedShifts(agenyId, page, size): Observable<any> {

    return this.http.get(`${this.base_url}/agency-shifts-released/${agenyId}/${page}/${size}`);

  }

  createShift(data): Observable<any> {
    return this.http.post(`${this.base_url}/shift`, data);
  }

  getShiftsClaims(shiftIds): Observable<any> {
    return this.http.get(`${this.base_url}/shift/expense-claim/${shiftIds}`);
  }

  rejectClaim(data): Observable<any> {
    return this.http.put(`${this.base_url}/shift/expense-claim`, data);
  }

  acceptClaim(data): Observable<any> {
    return this.http.put(`${this.base_url}/shift/expense-claim`, data);
  }

  updateShift(data): Observable<any> {
    return this.http.put(`${this.base_url}/shift`, data);
  }

  getShiftById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/shift/${ref}`);
  }

  deleteShift(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/shift/${ref}`);
  }

  authorizeShift(shift, client): Observable<any> {
    return this.http.put(`${this.base_url}/authorize-shift/${shift}`, null);
  }

  bookShift(shift, worker, agency): Observable<any> {
    return this.http.put(`${this.base_url}/book-shift/${shift}/${worker}/${agency}`, null);
  }

  newBookShift(shift, worker): Observable<any> {
    return this.http.put(`${this.base_url}/direct-book-shift/${shift}/${worker}`, null);
  }

  cancelShift(shift, client, reason): Observable<any> {
    return this.http.put(`${this.base_url}/cancel-shift/${shift}/${client}/${reason}`, null);
  }

  getAgencyShiftApplicants(shiftId, agencyId): Observable<any> {
    return this.http.get(`${this.base_url}/applicants/${shiftId}/${agencyId}`);
  }

  getShiftApplicants(shiftId): Observable<any> {
    return this.http.get(`${this.base_url}/shift-applicants/${shiftId}`);
  }

  approveAppliedShift(shift, worker): Observable<any> {
    return this.http.put(`${this.base_url}/approve-applied-shift/${shift}/${worker}/`, null);
  }



  getPaginatedClientShiftDirectorates(clientId,page, size): Observable<any> {
       return this.http.get(`${this.base_url}/shift-directorates-client/${clientId}/${page}/${size}`);
  }

  getPaginatedShiftDirectorates(searchCriteria: string, locationId:number, page, size): Observable<any> {
    if(this.userType == "client"){
      return this.http.get(`${this.base_url}/shift-directorates-client/${this.storageService.decrypt(localStorage.getItem('clientId'))}/${page}/${size}?searchCriteria=${searchCriteria??''}`);
    }
    else if(this.userType == "agency"){
      return this.http.get(`${this.base_url}/shift-directorates-agency/${this.storageService.decrypt(localStorage.getItem('agentId'))}/${page}/${size}?searchCriteria=${searchCriteria??''}`);
    }
    else{
      return this.http.get(`${this.base_url}/shift-directorates/${page}/${size}?searchCriteria=${searchCriteria??''}&locationId=${locationId??''}`);
    }

  }

  getShiftComplianceIssues(searchCriteria: string, agencyId:number, page, size): Observable<any> {
      return this.http.get(`${this.base_url}/shifts/compliance/issues/${agencyId}/${page}/${size}?searchCriteria=${searchCriteria??''}`);
  }

  getPaginatedAgencyShiftDirectorates(agency, page, size): Observable<any> {

      return this.http.get(`${this.base_url}/shift-directorates-agency/${agency}/${page}/${size}`);
  }

  getPaginatedClientDirectorates(page, size, client): Observable<any> {
    return this.http.get(`${this.base_url}/shift-directorates-client/${client}/${page}/${size}`);
  }

  createShiftDirectorate(data): Observable<any> {
    return this.http.post(`${this.base_url}/shift-directorate`, data);
  }

  updateShiftDirectorate(data): Observable<any> {
    return this.http.put(`${this.base_url}/shift-directorate`, data);
  }

  getShiftDirectorateById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/shift-directorate/${ref}`);
  }

  getShiftDirectorateByLocation(ref): Observable<any> {
    return this.http.get(`${this.base_url}/shift-directorate-by-location/${ref}`);
  }

  deleteShiftDirectorate(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/shift-directorate/${ref}`);
  }



  getPaginatedClientShiftLocations(clientId, page, size): Observable<any> {
       return this.http.get(`${this.base_url}/location-client/${clientId}`);


  }
  getPaginatedShiftLocations(page, size): Observable<any> {
    if(this.userType == "client"){
      return this.http.get(`${this.base_url}/locations/${page}/${1000}`);
      // return this.http.get(`${this.base_url}/location-client/${this.storageService.decrypt(localStorage.getItem('clientId'))}`);
    }
    else if(this.userType == "agency"){
      // return this.http.get(`${this.base_url}/location-agency/${this.storageService.decrypt(localStorage.getItem('agentId'))}/${page}/${size}`);
      return this.http.get(`${this.base_url}/locations/${page}/${1000}`);
    }
    else{
      return this.http.get(`${this.base_url}/locations/${page}/${1000}`);
    }

  }

  createShiftLocation(data): Observable<any> {
    return this.http.post(`${this.base_url}/location`, data);
  }

  updateShiftLocation(data): Observable<any> {
    return this.http.put(`${this.base_url}/location`, data);
  }

  getShiftLocationById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/location/${ref}`);
  }

  getShiftLocationByClient(ref): Observable<any> {
    return this.http.get(`${this.base_url}/location-client/${ref}`);
  }

  deleteShiftLocation(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/location/${ref}`);
  }



  getPaginatedShiftTypes(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/shift-types/${page}/${size}`);
  }

  createShiftType(data): Observable<any> {
    return this.http.post(`${this.base_url}/shift-type`, data);
  }

  updateShiftType(data): Observable<any> {
    return this.http.put(`${this.base_url}/shift-type`, data);
  }

  getShiftTypeById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/shift-type/${ref}`);
  }

  deleteShiftType(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/shift-type/${ref}`);
  }

}
