<main class="mt-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-3 text-left">
                <h3>Logs</h3>
            </div>
        </div>


    <section>
      <div class="">
          <div class="row text-center mx-0 my-1">
              <div class="col-6  pointer col-sm-4 col-md-3 " (click)="selectTab('NEW')"
              [ngClass]="{'bg-custom-danger': selectedTab == 'NEW','bg-custom-unselected': selectedTab != 'NEW'}" style="border-top-left-radius: 5px;">New
              <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'NEW'}" >{{shiftsSummary?.newShift!=0?shiftsSummary?.newShift:null}}</span>
            </div>
            <div class="col-6 col-sm-4 col-md-4 pointer text" (click)="selectTab('WAITING_APPROVAL')"
              [ngClass]="{'bg-custom-danger': selectedTab == 'WAITING_APPROVAL','bg-custom-unselected': selectedTab != 'WAITING_APPROVAL'}">Awaiting Authorisation
              <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'WAITING_APPROVAL'}" >{{shiftsSummary?.awaiting!=0?shiftsSummary?.awaiting:null}}</span>
            </div>
            <div class="col-6 col-sm-4 col-md-3 pointer " (click)="selectTab('APPROVED')"
              [ngClass]="{'bg-custom-danger': selectedTab == 'APPROVED','bg-custom-unselected': selectedTab != 'APPROVED'}" style="border-top-right-radius: 5px;">Closed
              <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'APPROVED'}" >{{shiftsSummary?.closed!=0?shiftsSummary?.closed:null}}</span>
            </div>

          </div>
      </div>
      <br>
      <br>


      <div class="row">

        <div class="col-md-3">
          <select name="" class="form-control" [(ngModel)]="clientFilter">
            <option value="" selected disabled>Log Type</option>
            <option *ngFor="let c of clients" [value]="c.id" selected >{{ c.name }}</option>
          </select>
        </div>


        <div class="col-md-3">
          <input type="date" class="form-control" [(ngModel)]="startDateFilter">
        </div>


        <div class="col-md-3">
          <select name="" class="form-control" [(ngModel)]="clientFilter">
            <option value="" selected disabled>Driver / Assignee</option>
            <option *ngFor="let c of clients" [value]="c.id" selected >{{ c.name }}</option>
          </select>
        </div>

        <!-- <div class="col-md-3">
          <button class="btn btn-success" (click)="openModal(add)">Create Deep Cleaning Log</button>
        </div> -->
      </div>
      <br>

      <div>
        <app-logs-table
        [agencyFilter]  =    "agencyFilter"
        [searchCriteria]  =    "searchCriteria"
        [clientFilter]  =    "clientFilter"
        [startDateFilter]  = "startDateFilter"
        [endDateFilter]  = "endDateFilter"
        [transportStatus] = "selectedTab"
        (updated)="refreshPage($event)"
        ></app-logs-table>
      </div>

  </section>
    </div>

</main>

<ng-template class="modal fade" #add let-modal>
  <app-deep-cleaning-log (updated)="refreshPage($event)"></app-deep-cleaning-log>
</ng-template>

