import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ShiftService } from 'src/app/shared/services/shift.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { WorkerService } from 'src/app/shared/services/worker.service';

declare var $: any;


@Component({
  selector: 'app-applied-shift',
  templateUrl: './applied-shift.component.html',
  styleUrls: ['./applied-shift.component.css']
})
export class AppliedShiftComponent implements OnInit {

  shiftInfo: any;
  availability: any =[];
  userType: string;
  applicants: any;
  shiftId: number;
  currentBookings: any= [];
  Bookings: any = [];
  start: any;

  constructor(
    private toast: ToastrService,
    private shiftsService: ShiftService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private authService: AuthService,
    private workerService: WorkerService,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
   }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.shiftId = params["id"];

      this.getShift();
    });
  }

  getShift(){
    this.shiftsService.getShiftById(this.shiftId).subscribe(
      data => {
        if(data.shiftStatus != 'NEW'){
          this.toast.warning('This shift is not NEW.');
          this.router.navigate([`/${this.storageService.decrypt(localStorage.getItem('userType'))}/shifts/list`]);
        }
        this.shiftInfo = data;
        let date = new Date(this.shiftInfo.start)
        date.setHours(0);
        this.start = date.toString();
        console.log('Booked date', this.start);
        this.getApplicants();
      }
    );
  }

  getWorkerShifts(workerId){
    this.workerService.getWorkerShifts(0, 1000000, workerId, "BOOKED").subscribe(
      data => {
        data.forEach(shift => {
              let bookDate = new Date(shift.start)
              bookDate.setHours(0);
              let strDate = bookDate.toString();
              // console.log('WorkerId', workerId);
              // console.log('Date', strDate);
              this.currentBookings.push(strDate);
              // console.log('this.currentBookings',this.currentBookings)
            });

            this.Bookings[workerId] = this.currentBookings;
              console.log('this.Bookings',this.Bookings);

              if(this.currentBookings.includes(this.start)){
                this.availability[workerId] = false;
                console.log("############################################")
                console.log(this.availability);

              }else{
                this.availability[workerId] = true;

                console.log("#poop#####")
                console.log(this.availability);

              }
            this.currentBookings = [];


      },
      err => {




      }
    );
  }

  // formatDate(date){
  //   const myArray = date.split("/");
  //   if(myArray.length>=2){
  //     let formateddate =myArray[2] + "-" +myArray[1] +"-"+myArray[0] ;
  //     return formateddate;
  //   }else{
  //     return date;
  //   }

  // }

  getApplicants(){
    if(this.storageService.decrypt(localStorage.getItem('agentId'))){

      this.shiftsService.getAgencyShiftApplicants(this.shiftInfo.id, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
        data => {
          this.applicants = data;
          this.applicants.forEach(r => {
            this.getWorkerShifts(r.id);

        });

        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }else{
      this.shiftsService.getShiftApplicants(this.shiftInfo.id).subscribe(
        data => {
          // console.log(data);

          this.applicants = data;

          this.applicants.forEach(r => {
              this.getWorkerShifts(r.id);

          });

        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
  }

  approveWorker(ref){
    if(ref){
      this.shiftsService.approveAppliedShift(this.shiftId, ref).subscribe(
        data => {
          // console.log(data);
          this.toast.success('Applicant Approved Successfully');
          this.router.navigate([`/${this.storageService.decrypt(localStorage.getItem('userType'))}/shifts/list`]);
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
    else{
      this.toast.warning('No Reference Found');
    }
  }

  detailsPage(ref){
    if(ref){
      this.router.navigate([`/${this.userType}/workers/info/${ref}`]);
    }
  }

}
