import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-view-vehicle',
  templateUrl: './view-vehicle.component.html'
})
export class ViewVehicleComponent implements OnInit {

selectedTab: String = "about";
logForm: FormGroup;
  transportId: any;
  job: any;


constructor(
  private toast: ToastrService,
  private usersService: UsersService,
  private storageService: StorageService,
  private transportService: TransportService,
  private fb: FormBuilder,
  private router: Router,
  private activateRoute: ActivatedRoute,
  private modalService: NgbModal
) {
  this.activateRoute.params.subscribe((params) => {
    this.transportId = params["id"];
    this.logForm = this.fb.group({
      id: [this.transportId],
      name: [''],
      notes: [''],
      regno: [''],
      description: [''],
      stock: [''],
      status: [''],
      type: [''],
      agency: [''],


      model: [''],
      color: [''],
      carType: [''],
      engineNumber: [''],
      engineSize: [''],
      trackerId: [''],
      contactPerson: [''],
      contactPhone: [''],
      contactAddress: [''],
      depositAmt: [''],
      maxDailyMileage: [''],
      mileageRate: [''],
      hourlyRate: [''],
      vehicleRates: this.fb.array([
        this.fb.group({
          rate : ["", Validators.required],
          weekDay : ["MTF"],
          desc : ["Mon - Fri"],
       }),
        this.fb.group({
          rate : ["", Validators.required],
          weekDay : ["SUN"],
          desc : ["Sun"],
       }),
        this.fb.group({
          rate : ["", Validators.required],
          weekDay : ["SAT"],
          desc : ["Sat"],
       }),
      ]),

    });
  });
}



  ngOnInit(): void {
    this.getJob();
  }

  getJob(){
    this.transportService.findVehicleById(this.transportId).subscribe(
      data => {
        this.job = data;
        this.logForm.patchValue(data);

        console.log(this.logForm);
      }
    );
  }

  selectTab(arg0: string) {
    this.selectedTab = arg0;
  }
  updateTab($event){
    this.selectedTab  = $event;
    this.getJob();

  }

}

