<main class="mt-5">
  <!-- Header part -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-8 text-left">
        <h3>Create Shift</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4 text-left ">
      </div>
    </div>
  </div>


  <!-- content -->
  <section class="content mt-4">
    <form [formGroup]="addForm" (ngSubmit)="(false)">
      <div class="container-fluid">
        <div class="row">
          <div *ngIf="userType=='client'" class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
              Select Agency
              <ng-select
                [items]="agencies"
                [virtualScroll]="true"
                bindLabel="name"
                bindValue="id"
                placeholder="choose agency"
                [multiple]="true"
                formControlName="agentIdList"
            ></ng-select>
            </div>
          </div>
          <div *ngIf="userType=='agency'" class="col-12 col-sm-12 col-md-6 col-lg-4 text-left mt-2 mt-md-0">
            Select Client
            <select formControlName="clientId" id="myClient" class="form-control" (change)="clientSelected(); checkClient()">
              <option value="" selected disabled>Select Client</option>
              <option *ngFor="let c of clients;" value="{{c.id}}">{{c.name}}</option>
            </select>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
              Shift Directorate
              <select formControlName="shiftDirectorateId" (change)="validateLocation($event)" class="form-control" required>
                <option value="" selected disabled>select Directorate</option>
                <option *ngFor="let d of directorates" value="{{ d.id }}">
                  {{ d.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
              Location Name
              <input type="text" maxlength="255" readonly placeholder="Location" id="locationId" class="form-control" required>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
              Flexi-Worker Assignment Code
              <select formControlName="assignmentCodeId" class="form-control" required>
                <option value="" selected disabled>
                  select Assignment Code
                </option>
                <option *ngFor="let a of assCodes" value="{{ a.id }}">
                  {{ a.name }}, {{a.code}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
              Gender
              <select formControlName="gender" class="form-control" required >
                <option value="" selected disabled>Select Gender</option>
                <option value="FEMALE">Female</option>
                <option value="MALE">Male</option>
                <option value="NO_PREFERENCE">No Preference</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
              Shift Type
              <select formControlName="shiftType" class="form-control" required>
                <option value="" selected disabled>select Type</option>
                <option *ngFor="let t of types" value="{{ t.id }}">
                  {{ t.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12">
            <div class="form-group">
              Notes
              <textarea
                formControlName="notes"
                placeholder="Notes... (200 characters)"
                cols="30"
                rows="5"
                class="form-control"
                maxlength="200"
                required
              ></textarea>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
              Show Notes To Agency
              <input
                type="checkbox"
                formControlName="showNoteToAgency"
                class="ml-2"
                checked="checked"
              />
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
              Show Notes To Flexi-Worker
              <input
                type="checkbox"
                formControlName="showNoteToFw"
                class="ml-2"
                checked="checked"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid text-right mt-5">
        <button type="reset" class="btn btn-danger">
         Clear
        </button>
        <button
          type="button"
          (click)="openModal(confirm)"
          class="ml-2 btn btn-success"
        >
          Create Shift
        </button>
        <span class="text-info-custom ml-2" *ngIf="loader">
          Please wait
        </span>
      </div>
    </form>
  </section>
</main>

<!-- Modals -->


<!-- Select type of shift to publish -->
<ng-template class="modal fade"  #confirm let-modal>
  <div class="border-0" id="createshiftmodal">
    <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">Create Shift</h5>
      <span
        type="button"
        class="btn close text-white"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <div class="bg-white card-body p-5" id="addContainer">
        <div class="container-fluid" id="12proceed" *ngIf="!quest" >
          <div class="row text-center">
            <div class="col-12">
              <button
              type="button"
              (click)="openModal(allworkers)"
              class="btn py-2 btn-success m-2 w-75"
              >
                Publish To All Workers
              </button>
            </div>
            <div class="col-12">
              <button
              type="button"
              (click)="openModal(requireapp)"
              class="btn py-2 btn-success m-2 w-75"
              >
                Require Application By Worker
              </button>
            </div>
            <div class="col-12">
              <button
              type="button"
              (click)="openModal(directbook)"
              class=" btn py-2 btn-success m-2 w-75"
              >
                Directly Book A Worker
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--Publish to all workers-->
<ng-template class="modal fade" #allworkers let-modal>
  <div class=" border-0">
    <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">Publish To All Workers</h5>
      <span
        type="button"
        class="btn close text-white"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="m-3" >
      <div class="" id="addContainer">
        <div class="" id="13proceed" >
          <div class="">
            <div class="">

              <form (ngSubmit)="(false)"   >
                <table class="table table-striped mb-0 styled-table text-center">
                  <thead class="rounded-top">
                      <tr class="text-center">
                          <th>Start</th>
                          <th>End </th>
                          <th>Break Time (mins)</th>
                          <th style="border-radius: 0; width: 80px;">Number OF SHIFTS</th>
                          <th style="border-radius: 0; width: 80px;">Add Row</th>
                      </tr>
                  </thead>
                  <tbody id="multipleShifts">
                      <tr *ngFor="let shift of shifts; let i = index" id="{{ i }}">
                          <td (mouseleave)="updateShifts(i, 'shiftStartDate')">
                            <div class="">
                              <div class="form-group">
                                <input
                                  type="datetime-local"
                                  id="shiftStartDate{{ i }}"
                                  min ="{{ this.date1 }}"
                                  class="form-control"
                                  value="{{ shift.start }}"
                                />
                              </div>
                            </div>
                          </td>
                          <td (mouseleave)="updateShifts(i)" >
                            <div class="">
                              <div class="form-group">
                                <input
                                  type="datetime-local"
                                  id="end{{ i }}"
                                  min ="{{ shift.minend}}"
                                  class="form-control"
                                  value="{{ shift.end }}"
                                />
                              </div>
                            </div>
                          </td>
                          <td (mouseleave)="updateShifts(i)">
                            <div class="">
                              <div class="form-group">
                                <div class="input-group">
                                  <input
                                  type="number"
                                  id="breaktime{{ i }}"
                                  class="form-control"
                                  value="{{ shift.breaktime}}"
                                />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td (mouseleave)="updateShifts(i)">
                            <div class="">
                              <div class="form-group">
                                  <select class="form-control" id="numberOfShifts{{ i }}"  value="{{ shift.numberofshifts }}" required>
                                    <option value="1" selected>1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                  </select>
                              </div>
                            </div>
                          </td>
                        <td class="d-flex">
                          <button
                            type="button"
                            class="m-auto btn"
                            (click)="addShift()"
                          >
                            <i class="bi bi-plus-lg"></i>
                          </button>
                          <button
                            type="button"
                            class="m-auto btn"
                            (click)="removeShift(i)"
                          >
                            <i class="fa fa-minus" aria-hidden="true"></i>
                          </button>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </form>

            </div>
            <div class="col-12">
              <button class="btn btn-block btn-success text-white " (click)="createShift(addForm, true, false, false)" style="background-color: #7A0159;">
                Publish To All Workers
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white card-body p-5" style="display: none;" id="newContainer">
        <div class="bg-modal card-body">
          <div class="text-center">
              <h3>Do you want to add another New Shift?</h3>
          </div>
        </div>
        <div align="right" class="mt-3">
          <button class="btn btn-danger btn-sm mr-3 " (click)="newShiftsPage()">
              No
          </button>
          <button class="btn btn-sm btn-succes" (click)="dismissModal()">
              Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Require application by worker -->
<ng-template class="modal fade" #requireapp let-modal>
  <div class=" border-0">
    <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">Require Application From Worker</h5>
      <span
        type="button"
        class="btn close text-white"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="mb-3">
      <div class="" id="addContainer">
        <div class="" id="13proceed" >
          <div class="">
            <div class="">

              <form (ngSubmit)="(false)"   >
                <table class="table table-striped mb-0 styled-table text-center">
                  <thead class="rounded-top">
                      <tr class="text-center">
                          <th>Start</th>
                          <th>End </th>
                          <th>Break Time (mins)</th>
                          <th style="border-radius: 0; width: 80px;">Number OF SHIFTS</th>
                          <th style="border-radius: 0; width: 80px;">Add Row</th>
                      </tr>
                  </thead>
                  <tbody id="multipleShifts">
                      <tr *ngFor="let shift of shifts; let i = index" id="{{ i }}">
                          <td (mouseleave)="updateShifts(i, 'shiftStartDate')">
                            <div class="">
                              <div class="form-group">
                                <input
                                  type="datetime-local"
                                  id="shiftStartDate{{ i }}"
                                  min ="{{ this.date1 }}"
                                  class="form-control"
                                  value="{{ shift.start }}"
                                />
                              </div>
                            </div>
                          </td>
                          <td (mouseleave)="updateShifts(i)">
                            <div class="">
                              <div class="form-group">
                                <input
                                  type="datetime-local"
                                  id="end{{ i }}"
                                  min ="{{ shift.minend}}"
                                  class="form-control"
                                  value="{{ shift.end }}"
                                />
                              </div>
                            </div>
                          </td>
                          <td (mouseleave)="updateShifts(i)">
                            <div class="">
                              <div class="form-group">
                                <div class="input-group">
                                  <input
                                  type="number"
                                  id="breaktime{{ i }}"
                                  class="form-control"
                                  value="{{ shift.breaktime}}"
                                />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td (mouseleave)="updateShifts(i)">
                            <div class="">
                              <div class="form-group">
                                  <select class="form-control" id="numberOfShifts{{ i }}"  value="{{ shift.numberofshifts }}" required>
                                    <option value="1" selected>1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                  </select>
                              </div>
                            </div>
                          </td>
                        <td class="d-flex">
                          <button
                            type="button"
                            class="m-auto btn"
                            (click)="addShift()"
                          >
                            <i class="bi bi-plus-lg"></i>
                          </button>
                          <button
                            type="button"
                            class="m-auto btn"
                            (click)="removeShift(i)"
                          >
                            <i class="fa fa-minus" aria-hidden="true"></i>
                          </button>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </form>

            </div>
            <div class="col-12">
              <button class="btn btn-block btn-success text-white " (click)="createShift(addForm, false, true, false)" style="background-color: #7A0159;">
                Publish Shift
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white card-body p-5" style="display: none;" id="newContainer">
        <div class="bg-modal card-body">
          <div class="text-center">
              <h3>Do you want to add another New Shift?</h3>
          </div>
        </div>
        <div align="right" class="mt-3">
          <button class="btn btn-danger btn-sm mr-3 " (click)="newShiftsPage()">
              No
          </button>
          <button class="btn btn-sm btn-success " (click)="dismissModal()">
              Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Directly book a worker -->
<ng-template class="modal fade" #directbook let-modal>
  <div class=" border-0">
    <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">Directly Book Workers</h5>
      <span
        type="button"
        class="btn close text-white"
        data-dismiss="modal"
        aria-label="Close"
        (click)="dismissModal()"
      >
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="m-3">
      <div class="" id="addContainer">
        <div class="" id="13proceed" >
          <div class="">
            <div class="">

              <form (ngSubmit)="(false)"  style="overflow-x: scroll;" >
                <table class="table table-striped mb-0 styled-table text-center">
                  <thead class="rounded-top">
                      <tr class="text-center">
                          <th>Start</th>
                          <th>End</th>
                          <th style="min-width: 150px;">Break Time</th>
                          <th style="border-radius: 0; width: 80px;">Add Row</th>
                      </tr>
                  </thead>
                  <tbody id="multipleShifts" *ngFor="let shift of shifts; let i = index" id="{{ i }}">
                      <tr >
                          <td (mouseleave)="updateShifts(i, 'shiftStartDate')">
                            <div class="">
                              <div class="form-group">
                                <input
                                  type="datetime-local"
                                  id="shiftStartDate{{ i }}"
                                  class="form-control"
                                  value="{{ shift.start }}"
                                />
                              </div>
                            </div>
                          </td>
                          <td (mouseleave)="updateShifts(i)">
                            <div class="">
                              <div class="form-group">
                                <input
                                  type="datetime-local"
                                  id="end{{ i }}"
                                  min=" {{ shift.minend }}"
                                  class="form-control"
                                  value="{{ shift.end }}"
                                />
                              </div>
                            </div>
                          </td>

                          <td (mouseleave)="updateShifts(i)">
                            <div class="">
                              <div class="form-group">
                                <div class="input-group">
                                  <input
                                  type="number"
                                  id="breaktime{{ i }}"
                                  class="form-control"
                                  value="{{ shift.breaktime}}"
                                />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="d-flex">
                            <button
                              type="button"
                              class="m-auto btn"
                              (click)="addShift()"
                            >
                              <i class="bi bi-plus-lg"></i>
                            </button>
                            <button
                              type="button"
                              class="m-auto btn"
                              (click)="removeShift(i)"
                            >
                              <i class="fa fa-minus" aria-hidden="true"></i>
                            </button>
                          </td>
                      </tr>
                      <tr (mouseenter)="setActiveShift(i)">
                        <td>
                          <label for="">Book Worker</label>
                        </td>
                        <td>
                                <input class="form-control" id="numberOfShifts{{ i }}" value="1" type="hidden">
                                <select class="form-control" id="worker{{ i }}"  (change)="updateShifts(i, 'worker')"  style="width: 200px;" required>
                                  <option value="" selected disabled >Select A Worker</option>
                                  <optgroup *ngFor="let a of agencies"   label="{{a.name}}">
                                    <option *ngFor="let d of workers"  value="{{a.id}},{{d.id}}" [hidden]="d.agencyId!=a.id" [selected]="d.id==shift.worker && a.id==shift.agency">
                                      {{ d.firstname}} {{ d.lastname }}
                                    </option>
                                  </optgroup>

                                </select>
                        </td>
                        <td>Check Worker Availability</td>
                        <td >
                          <!-- <input matInput [matDatepicker]="dp3" style="width:0; visibility: hidden" disabled> -->
                          <mat-datepicker-toggle matSuffix (click)="openDateModal(availability,i)" ></mat-datepicker-toggle>
                          <!-- <mat-datepicker [dateClass]="dateClass"  #dp3 disabled="false" ></mat-datepicker> -->
                        </td>
                        <td>
                          <p class="text-danger-custom" *ngIf="checkConflict(i)==true"><small>Conflict detected on shift</small></p>
                          <p class="text-danger-custom" *ngIf="checkBookingConflict(i)==true"><small>You already booked this worker on this date.</small></p>
                         </td>
                        <td>

                        </td>
                      </tr>
                      <tr *ngIf="toggleDate==i">
                        <td></td>
                        <td></td>
                        <td>
                          <button type="button" class="ml-4 btn">
                            <i class="bi bi-circle-fill " style="color: rgb(69, 71, 233);"></i>&nbsp;&nbsp;
                            <br>Booked
                          </button>
                        </td>
                        <td>
                          <button type="button" class="ml-4 btn">
                            <i class="bi bi-circle-fill " style="color: rgb(233, 74, 69);"></i>&nbsp;&nbsp;
                            <br>UNAVAILABLE
                          </button>
                        </td>
                        <td>
                           <button type="button" class="ml-4 btn">
                            <i class="bi bi-circle-fill border rounded-1" style="color: rgb(255, 255, 255);"></i>&nbsp;&nbsp;
                            <br>AVAILABLE
                          </button>
                        </td>


                      </tr>
                  </tbody>
                </table>
              </form>

            </div>


            <div class="col-12">
              <button class="btn btn-success text-white "  (click)="createShift(addForm, false, false, true)" style="background-color: #7A0159;">
                Publish and Book
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white card-body p-5" style="display: none;" id="newContainer">
        <div class="bg-modal card-body">
          <div class="text-center">
              <h3>Do you want to add another New Shift?</h3>
          </div>
        </div>
        <div align="right" class="mt-3">
          <button class="btn btn-danger btn-sm mr-3 " (click)="newShiftsPage()">
              No
          </button>
          <button class="btn btn-sm btn-success " (click)="dismissModal()">
              Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!-- Availability -->
<ng-template class="modal fade" #availability let-modal>
  <div class=" border-0">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">Flexi-Worker Availability</h5>
    <span
      type="button"
      class="btn close text-white"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="m-4">
      <div class="m-4">
    <button type="button" class="ml-4 btn">
      <i class="bi bi-circle-fill " style="color: rgb(49, 120, 252); font-size: 40px;"></i>&nbsp;&nbsp;
      <br>Booked
    </button>

    <button type="button" class="ml-4 btn">
      <i class="bi bi-circle-fill " style="color: rgb(233, 74, 69); font-size: 40px;"></i>&nbsp;&nbsp;
      <br>UNAVAILABLE
    </button>

    <button type="button" class="ml-4 btn">
      <i class="bi bi-circle-fill " style="color: rgb(235, 232, 232); font-size: 40px;"></i>&nbsp;&nbsp;
      <br>AVAILABLE
    </button>

    <!-- <p class="text-primary-custom m-4">
      <i class="bi bi-info-circle"></i>&nbsp;&nbsp;
      Tap on a date to view details for a booked shift
    </p> -->
  </div>
  <mat-calendar [dateClass]="dateClass()"  #dp3 disabled="false" ></mat-calendar>

  </div>


  </div>
</ng-template>
