<main class="mt-5">
  <!-- Header part -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4 text-left">
        <h3>Clients</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4 text-center">
        <div class="input-group">
          <input
            type="text"
            class="form-control col-8 border-right-0"
            placeholder="Search Client"
            aria-describedby="inputGroupPrepend2"
            required
            [(ngModel)]="searchCriteria"
          />
          <div   (click)="searchClient()" class="input-group-prepend">
            <span
              class="input-group-text bg-transparent border-left-0"
              id="inputGroupPrepend2"
            >
              <i class="fa fa-search"  ></i>
            </span>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0"
      >
        <button class="btn btn-success" *ngIf="userType == 'agency'&&'UPDATE_CLIENT'|permission" (click)="getAllClients(); formInit(add);" title="Add a new client or link with existing client.">
          <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Add Client
        </button>
      </div>
    </div>
  </div>

  <!-- Content -->
  <section class="content mt-4">
    <div class="container-fluid">
      <table
        class="
          table table-striped
          mb-0
          styled-table
          text-left
        "
      >
        <thead>
          <tr class="text-left">
            <th>Client Name</th>
            <th>Service</th>
            <th>Phone</th>
            <th>Email</th>
            <!-- <th>Status</th> -->

            <!-- <th *ngIf="userType != 'agency'">Actions</th> -->
            <th> Purchase Order #</th>
            <th>SBS Code</th>
            <th>Actions</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let c of clients;">
            <td class="text-primary-custom">{{c.name}}</td>
            <td>{{c.service}}</td>
            <td>{{c.telephone}}</td>
            <td>{{c.email}}</td>
            <!-- <td>
              <span *ngIf="c.status == 'ACTIVE'" title="Active">
                <i class="fa fa-square text-success"></i>
              </span>
              <span *ngIf="c.status == 'INACTIVE'">
                <i class="fa fa-square text-danger-custom" title="Inactive"></i>
              </span>
            </td> -->

            <td>{{c.purchaseOrder}}</td>

            <td>{{c.sbsCode}}</td>

            <td *ngIf="userType != 'agency'" >
              <span class="mr-3 text-primary-custom pointer"  *ngIf="userType == 'client'" >
                <i class="fa fa-eye" title="view"></i>
              </span>
              <span class="mr-3 text-success pointer" *ngIf="userType == 'admin'" title="Edit Agency Details" (click)="openModal(edit)">
                <i class="fa fa-pencil-alt" title="edit"></i>
              </span>
              <span class="text-primary-custom pointer" title="Manage Users" (click)="openBigModal(users, c.id)" *ngIf="userType == 'admin'">
                <i class="fa fa-users-cog"></i>
              </span>
            </td>
            <td *ngIf="userType == 'agency' ">
              <span
              *ngIf="'UPDATE_CLIENT'| permission"
              class="mr-3 text-success pointer"
              (click)="loadClient(c.id, edit, true)"
              title="View or edit client info"
            >
              <i class="fa fa-pencil-alt" title="edit"></i>
            </span>
            <span class="text-primary-custom pointer" title="Manage Users" (click)="openBigModal(users, c.id)">
              <i class="fa fa-users-cog"></i>
            </span>


            </td>
          </tr>
        </tbody>
      </table>
      <section class="pagination-container" *ngIf="clients">
        <div class="container-fluid">
          <div class="row m-0">
            <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
              Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
            </div>
            <div class="col-12 col-sm-6 col-md-4 text-right">
              <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
                <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
              </button>
            </div>
            <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
              <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
                Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</main>

<!-- Modals -->
<ng-template class="modal fade" #add let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">Add New Client</h5>
      <span
        type="button"
        class="btn close text-white"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <form [formGroup]="addForm" (ngSubmit)="newClient(addForm)">
        <div class="bg-modal card-body">
          <div class="container-fluid">
            <section>
              <div class="col-12">
                <i class="bi bi-info-circle"></i>
                <span class="ml-2">About Client</span>
                <hr class="bg-black mt-0" />
              </div>
              <div class="row">
                <!-- <div class="form-group col-12 col-sm-12 col-md-6">
                  <input
                    type="text"
                    formControlName="name"
                    placeholder="Client Name"
                    class="form-control"
                  />
                </div> -->
                <div class="form-group col-12 col-sm-12 col-md-12">
                  <select id="clientList" (change)="loadClientInfo($event)" class="form-control">
                    <option value="" selected disabled>Select Other Client</option>
                    <option value="NEW" >New Client</option>
                    <option *ngFor="let a of allClients;" value="{{a.id}}">{{a.name}}</option>
                  </select>
                </div>
              </div>
              <fieldset>
                <div class="row">
                  <div class="form-group col-12 col-sm-12 col-md-6">
                    <input
                      type="text"
                      formControlName="name"
                      placeholder="Client Name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6">
                    <select formControlName="serviceId" class="form-control">
                      <option value="" selected disabled>Select Services</option>
                      <option *ngFor="let s of services;" value="{{s.id}}">{{s.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 mt-4">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Contacts and Addresses</span>
                  <hr class="bg-black mt-0" />
                </div>
                <div formGroupName="address">
                  <div class="row">
                    <div class="form-group col-12 col-sm-12 col-md-12">
                      <input
                        type="text"
                        formControlName="firstLine"
                        placeholder="First Line Address"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 col-sm-12 col-md-12">
                      <input
                        type="text"
                        formControlName="town"
                        placeholder="Town"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 col-sm-12 col-md-12">
                      <input
                        type="text"
                        formControlName="postcode"
                        placeholder="Post Code"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <input
                      type="text"
                      formControlName="telephone"
                      placeholder="Telephone"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <input
                      type="email" (keyup)="checkEmail($event)"
                      formControlName="email"
                      placeholder="Email"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12 mt-4" [hidden]="existing">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Admin</span>
                  <hr class="bg-black mt-0" />
                </div>
                <div class="row" formGroupName="administratorCreateDto" [hidden]="existing">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <input
                      type="email"
                      formControlName="adminEmail"
                      (keyup)="checkEmail($event)"
                      placeholder="Admin Email"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <input
                      type="text"
                      formControlName="firstname"
                      placeholder="Admin First Name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <input
                      type="text"
                      formControlName="lastname"
                      placeholder="Admin Last Name"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12 mt-4">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Billing</span>
                  <hr class="bg-black mt-0" />
                </div>
                <div class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <input
                      type="email"
                      formControlName="billingEmail"
                      (keyup)="checkEmail($event)"
                      placeholder="Billing Email"
                      class="form-control"
                    />
                  </div>
                </div>
<!-- Purchase Order number -->
            <div class="col-12 mt-4">
              <i class="bi bi-info-circle"></i>
              <span class="ml-2">Purchase Order Number</span>
              <hr class="bg-black mt-0" />
            </div>


            <div class="row">
              <div class="form-group col-12 col-sm-12 col-md-12">
                <input
                  type="email"
                  formControlName="purchaseOrder"
                  (keyup)="checkEmail($event)"
                  placeholder="Purchase order number"
                  class="form-control"
                />
              </div>
            </div>
        <!-- Ends here -->
        <div class="col-12 mt-4">
          <i class="bi bi-info-circle"></i>
          <span class="ml-2">SBS Code</span>
          <hr class="bg-black mt-0" />
        </div>


        <div class="row">
          <div class="form-group col-12 col-sm-12 col-md-12">
            <input
              type="email"
              formControlName="sbsCode"
              (keyup)="checkEmail($event)"
              placeholder="SBS Code"
              class="form-control"
            />
          </div>
        </div>

<!-- Ends here -->

              </fieldset>
            </section>
          </div>
        </div>
        <div align="right" class="mt-3">
          <button
            type="button"
            class="btn btn-danger btn-sm mr-3"
            (click)="modal.dismiss()"
          >
            Cancel
          </button>
          <button type="submit" *ngIf="new" class="btn btn-sm btn-success">
            Add Client
          </button>
          <button type="button" *ngIf="existing" (click)="createLinkToAgent()" class="btn btn-sm btn-success">
            Add Client Link
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>



<ng-template class="modal fade" #edit let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">View Client Information</h5>
      <span
        type="button"
        class="btn close text-white"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <form [formGroup]="editForm" (ngSubmit)="updateClient(editForm)">
        <div class="bg-modal card-body">
          <div class="container-fluid">
            <section>
              <div class="col-12">
                <i class="bi bi-info-circle"></i>
                <span class="ml-2">About Client</span>
                <hr class="bg-black mt-0" />
              </div>
              <div class="row">
                <div class="form-group col-12 col-sm-12 col-md-12">
                  <input
                    type="text"
                    formControlName="name"
                    placeholder="Client Name"
                    class="form-control"
                    disabled
                  />
                </div>
                <!-- <div class="form-group col-12 col-sm-12 col-md-6">
                  <select formControlName="agencyId" class="form-control">
                    <option value="" selected disabled>Select Agency</option>
                    <option *ngFor="let a of agencies;" value="{{a.agencyId}}">{{a.name}}</option>
                  </select>
                </div> -->
              </div>
              <div class="row">
                <!-- <div class="form-group col-12 col-sm-12 col-md-6">
                  <select formControlName="status" class="form-control">
                    <option value="" selected disabled>Select Status</option>
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </select>
                </div> -->
                <div class="form-group col-12 col-sm-12 col-md-12">
                  <select formControlName="serviceId" class="form-control">
                    <option value="" selected disabled>Select Services</option>
                    <option *ngFor="let s of services;" value="{{s.id}}">{{s.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-12 mt-4">
                <i class="bi bi-info-circle"></i>
                <span class="ml-2">Contacts and Addresses</span>
                <hr class="bg-black mt-0" />
              </div>
              <div formGroupName="address" class="row">
                  <div class="form-group col-12 col-sm-12 col-md-6">
                    <input
                      type="text"
                      formControlName="firstLine"
                      placeholder="First Line Address"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6">
                    <input
                      type="text"
                      formControlName="town"
                      placeholder="Town"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6">
                    <input
                      type="text"
                      formControlName="postcode"
                      placeholder="Post Code"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-12 col-sm-12 col-md-6">
                    <input
                      type="text"
                      formControlName="telephone"
                      placeholder="Telephone"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6">
                    <input
                      type="email" (keyup)="checkEmail($event)"
                      formControlName="email"
                      placeholder="Email"
                      class="form-control"
                      disabled
                    />
                  </div>
                </div>



               <div class="col-12 mt-4">
                <i class="bi bi-info-circle"></i>
                <span class="ml-2">Billing</span>
                <hr class="bg-black mt-0" />
              </div>
              <div class="row">
                <div class="form-group col-12 col-sm-12 col-md-12">
                  <input
                    type="email"
                    formControlName="billingEmail"
                    (keyup)="checkEmail($event)"
                    placeholder="Billing Email"
                    class="form-control"
                  />
                </div>
              </div>

              <!-- Puchase order starts here  -->
              <div class="col-12 mt-4">
                <i class="bi bi-info-circle"></i>
                <span class="ml-2">Purchase order Number</span>
                <hr class="bg-black mt-0" />
              </div>

              <div class="row">
                <div class="form-group col-12 col-sm-12 col-md-12">
                  <input
                    type="email"
                    formControlName="purchaseOrder"
                    placeholder="Purchase order Number"
                    class="form-control"
                  />
                </div>
              </div>

              <!-- ENds here  -->

              <div class="col-12 mt-4">
                <i class="bi bi-info-circle"></i>
                <span class="ml-2">SBS Code</span>
                <hr class="bg-black mt-0" />
              </div>

              <div class="row">
                <div class="form-group col-12 col-sm-12 col-md-12">
                  <input
                    type="email"
                    formControlName="sbsCode"
                    placeholder="Billing Email"
                    class="form-control"
                  />
                </div>
              </div>


               <!-- Sbs code starts here   -->




            </section>
          </div>
        </div>
        <div align="right" class="mt-3" >
          <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
            Cancel
          </button>
          <button type="submit" class="btn btn-sm btn-success">
            Update Client
          </button>
        </div>
        <!--div align="right" *ngIf="userType == 'agency'" class="mt-3">
          <button
            type="button"
            class="btn btn-danger btn-sm mr-3"
            (click)="modal.dismiss()"
          >
            Cancel
          </button>
          <button *ngIf="'UPDATE_CLIENT'|permission" type="submit" class="btn btn-sm btn-success">
            Update Client
          </button>
        </div-->
      </form>
    </div>
  </div>
</ng-template>

<ng-template class="modal fade" #delete let-modal>
  <div class="">
    <div class="modal-header bg-danger text-white">
      <h5 class="modal-title text-center w-100">Delete CLient</h5>
      <span
        type="button"
        class="btn close text-white"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <div class="bg-modal card-body">
        <div class="text-center">
          <h3>You are about to delete this Client?</h3>
        </div>
      </div>
      <div align="right" class="mt-3">
        <button
          class="btn btn-info btn-sm mr-3"
          (click)="modal.dismiss()"
        >
          Cancel
        </button>
        <button
          class="btn btn-sm btn-danger"
          (click)="deleteClient()"
        >
          Delete CLient
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template class="modal fade" #users let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">View Client Users</h5>
      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <table class="table table-striped mb-0 styled-table text-center">
        <thead>
            <tr class="text-center">
                <th>Name</th>
                <!-- <th>Username</th> -->
                <th>Email</th>
                <!-- <th>Phone</th> -->
                <th>Role</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody *ngIf="userList">
            <tr *ngFor="let u of userList;">
                <td>{{u.firstname}}</td>
                <!-- <td>{{u.username}} </td> -->
                <td>{{u.email}}</td>
                <td>{{getName(u.roles[0].name)}}</td>
                <!-- <td></td> -->
                <td>
                    <span *ngIf="u.enabled == 'Active'">
                        <i class="fa fa-square text-success"></i>
                    </span>
                    <span *ngIf="u.enabled == 'Inactive'">
                      <i class="fa fa-square text-danger-custom"></i>
                  </span>
                </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
