import { W } from '@angular/cdk/keycodes';
import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AssetService } from 'src/app/shared/services/asset.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { JobService } from 'src/app/shared/services/job.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { WorkerService } from 'src/app/shared/services/worker.service';

@Component({
  selector: 'app-job-plan-tab',
  templateUrl: './job-plan-tab.html',
  styleUrls: ['./job-plan-tab.css']
})
export class JobPlanTabComponent implements OnInit {

  @Input('selectedJob') selectedJob : any;
  @Input('teamLeaderView') teamLeaderView = false ;
  @Output() updated = new EventEmitter<boolean>();

  workers:any;
  vehicles: any;
  bookedWorkers=[];
  workerTimes = [];
  authorizeForm: FormGroup;
  userType: any;
  riskDoc: any;
  addForm: FormGroup;
  extremeRag: Array<String> = ['S','H','M'];
  uploadDoc: boolean = false;
  agencyId: any;
viewFullDetails: boolean = false;

  constructor(
    private storageService: StorageService,
    private agencyService: AgencyService,
    private assetService: AssetService,
    private transportService: TransportService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private authService: AuthService,
    private workerService: WorkerService,
    private jobsService: JobService
   ) {
    this.userType = authService.getUserType();
    this.agencyId =this.storageService.decrypt(localStorage.getItem('agentId'));
   }

  ngOnInit(): void {


    this.viewFullDetails = this.selectedJob.transportStatus == "NEW";

    if(this.userType!='client')this.getVehicles();
    if(this.userType=='agency'&&this.selectedJob.transportStatus!='NEW')this.getWorkers();

    console.log(this.selectedJob);
    this.authorizeForm = this.fb.group({
      transportId: [this.selectedJob.id, Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      breakTime: ['', Validators.required],
    });

    this.initForm();
    this.addForm.disable();

  }


  uploadWorkerTimes(){
    let data = {
      id: this.selectedJob.id,
      times: this.workerTimes
    };
    this.transportService.uploadWorkerTimes(data).subscribe(
      data => {
        this.toast.success("Shift times updated")
      }
    );
  }








  openDoc(selectedJo){
    this.workerService.openDoc(selectedJo);
  }


  setTime($event: any,arg1: any) {
    let time = this.workerTimes.filter(w=> w.bookingId == arg1);
    if(time.length==1)
      this.workerTimes  = this.workerTimes.map(item => item.bookingId === arg1 ?  { ...item, start: $event.target.value }: item );
    else
      this.workerTimes.push({bookingId: arg1,start: $event.target.value });
  }

  setEndTime($event: any,arg1: any) {
    let time = this.workerTimes.filter(w=> w.bookingId == arg1);
    if(time.length==1)
      this.workerTimes  = this.workerTimes.map(item => item.bookingId === arg1 ?  { ...item, end: $event.target.value }: item );
    else
      this.workerTimes.push({bookingId: arg1,end: $event.target.value });
  }


  getVehicles(){

    this.assetService.getAgencyAssets(this.storageService.decrypt(localStorage.getItem('agentId'))??this.selectedJob.agencyId,'VEHICLE',"", 0, 1000).subscribe(
      data => {
        let array = data.content;
        array.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
        this.vehicles = array;
      }
    );
  }


  getWorkers(){
      this.agencyService.getLegibleTransportWorkers(this.selectedJob.id).subscribe(
        data => {
          this.workers = data;
        }
      );
  }

  getSpecWorkers(id){
    let woks = this.workers?.filter(wk=>wk.workerSpecId == id);
    return woks==undefined?[]:woks[0]?.workers;
  }













  openModal(modal) {
    this.modalService.dismissAll();
    this.modalService.open(modal, {centered: true, size: 'lg'})
  }

  closeModals(){
    this.modalService.dismissAll();
  };

  initForm(){
    this.addForm = this.fb.group({
      transportLegibleAgencyIds : [""],
      pickupDirectorateId : [],
      id : [],
      version: [],
      agencyId : [""],
      clientId : [""],
      dateTimeRequired : ["", Validators.required],
      destination : ["", Validators.required],
      destinationContactNumber : [""],
      destinationPostCode : ["", Validators.required],
      escortServiceRisk : [],
      passengerAdditionalRisks : [],
      wardEscort   : [""],
      areStaffEscortingPatient   : ["",Validators.required],
      patientMedication   : ["",Validators.required],
      pmeds   : [""],
      disclaimer   : ["", Validators.required],
      isPassengerAwareOfTransport : ['', Validators.required],
      passengerGender : ["", Validators.required],
      physicalAggression : [],
      verballyAggressive : [],
      assaultStaffDesc : [""],
      selfNeglect : [""],
      selfNeglectDesc : [""],
      selfHarmDesc : [""],
      physicalAggressionDesc : [""],
      verballyAggressiveDesc : [""],
      absconsionRiskDesc : [""],
      genderIssuesDesc : [""],
      racialIssuesDesc : [""],
      sexuallyInappropriateDesc : [""],
      mentalHealthStatus : ["", Validators.required],
      specialRequests : [],
      patientName : ["", Validators.required],
      sexuallyInappropriate : [],
      selfHarm : [],
      reasonsForRestrains : [],
      reasonForTransport : ["",Validators.required],
      passengerRequiresRestraints : ["", Validators.required],
      pickupLocationContactNumber : [],
      pickupPostCode : ["", Validators.required],
      assaultStaff : [],
      absconsionRisk: [],
      otherRisks: [],
      otherMobilityIssues: [],
      mobilityIssues: [],
      transportWorkerSpecList : this.fb.array([
        this.fb.group({
          assignmentCode : ["1", Validators.required],
          gender : ["NO_PREFERENCE"],
          numberOfStaff : ["0",Validators.required],
       }),
        this.fb.group({
          assignmentCode : ["2", Validators.required],
          gender : ["NO_PREFERENCE"],
          numberOfStaff : ["0",Validators.required],
       }),
        this.fb.group({
          assignmentCode : ["32", Validators.required],
          gender : ["NO_PREFERENCE"],
          numberOfStaff : ["0",Validators.required],
       }),
     ]),
    mha:[],
    pcaddress:["",Validators.required],
    pcemail:["",[Validators.required,, Validators.email]],
    pcbusiness:[],
    pward:[],
    pname:["",Validators.required],
    pdob:["",Validators.required],
    walk:["",Validators.required],
    walkInfo:[""],
    nhs:[],
    diagnosis:[],
    dname:[""],
    dbusiness:[],
    dward:[],
    dcontact:[],
    demail:[, Validators.email],
    bpostCode:[],
    genderIssues:[],
    racialIssues:[],
    medication:[],
    physicalHealth:[],
    rapidTranq:[],
    infectionControl:[],
    covid:[],
    offerFood:[],
    allergies:[],
    submittedBy:[],
    semail:["",Validators.required],
    sphone:[],
    porderNum:[],
    sbsCode:[],
    bname:[],
    baddress:[],
    binvoice:[],
    bphone:[],
    bemail:[],
    authority:[],
    });

    this.formFromJson();
  }


  formFromJson(){
    if (this.selectedJob != null) {
      this.addForm.patchValue(this.selectedJob);
   }
  }



  onSubmit() {
    if (this.addForm.valid) {
      // Here, the form values will automatically populate the form controls
      console.log('Form Submitted', this.addForm.value)
    } else {
      console.log('Form is invalid');
    }
  }
}
