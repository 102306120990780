import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { WorkerService } from 'src/app/shared/services/worker.service';

declare var $: any;

@Component({
  selector: 'app-leader-entry',
  templateUrl: './leader-entry.component.html',
  styleUrls: ['./leader-entry.css']
})
export class LeaderEntryComponent implements OnInit {

  @ViewChild('edit', { static: true }) editTemplate;

  tabindex= 0;
  logForm: FormGroup;
  cleaningLogForm: FormGroup;
  transportId: any;
  job: any;

  selectedTab: string = 'property';
  loginForm: FormGroup;
  msg: string;
  new: boolean;
  existing: boolean;
  formArea: boolean;
  addForm: FormGroup;
  authorized = false;
  workerId: any;
  agencyId: any;
  isLoggedIn: boolean = false;


  constructor(
    private toast: ToastrService,
    private usersService: UsersService,
    private storageService: StorageService,
    private transportService: TransportService,
    private fb: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,

    private modalService: NgbModal,
    private authService: AuthService,
    private workerService: WorkerService,
  ) {
    this.logout();
    this.initForm();
    this.activateRoute.params.subscribe((params) => {
      this.transportId = params["id"];
    });
  }

  ngOnInit(): void {
    this.openModal();
  }

  initForm(){
      this.logForm = this.fb.group({
        cashHandover: [''],
        id: [''],
        medicationList: [''],
        dropTime:[''],
        pcomment: [],
        newAddress: [""],
        newEmail: [""],
        newPhone: [""],
        newPostCode: [""],
        pdroppedOff: true,
        pfAdvice: [''],
        pfCleanliness: [''],
        pfComfort: [''],
        pfCourtesy: [''],
        pfExperience: [''],
        pfKnowledge : [''],
        pfTreatment : [''],
        propertyList : [[]],
        signature: [''],
        patientRecipient: [""],
        recipientContact: [""],
        cleanliness: [""],
        recipientName: [""],
        recipientSignature: [""],
        recipientRole:[""],
        overallExperience:[""],
        newLocation:[false],
    });


    this.cleaningLogForm = this.fb.group({
      id: [''],
      startMileage: [''],
      endMileage: [''],
      totalMileage: [''],
      notes: [''],
      feedback: [''],
      damageReport: [''],
      indicators: [''],
      drivingControls: [''],
      wheelcondition: [''],
      tyreInflation: [''],
      brakes: [''],
      windscreen: [''],
      mirrors: [''],
      speedometer: [''],
      battery: [''],
      fuel: [''],
      seatbelt: [''],
      doors: [''],
      oil: [''],
      engineCheckLight: [''],
      warningLight: [''],
      litter: [''],
      hardSurface: [''],
      seats: [''],
      equipment: [''],
      sanitizer: [''],



      lamp: [''],
      sideReapter: [''],
      markers: [''],
      warningDevices: [''],
      reflectors: [''],
      body: [''],
      warningdevices: [''],
      mirror: [''],
      stoplamp: [''],
      fluidLeaks: [''],
      coolant: [''],
      drivingcontrol: [''],
      horn: [''],
      wipers: [''],
      washers: [''],
      fluidleaks: [''],
      exhaust: [''],
      InstrumentalPanel: [''],
      adblue: [''],
      trailercoupling: [''],




      cellArea: [''],
      vehicleId: [''],
      transportId: [this.transportId],








    });

    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  logout() {
    this.authService.doLogoutWithoutRedirect()
  }

  openModal() {
    this.modalService.open(this.editTemplate, {centered: true, size: 'lg'});
  }


  getWorker(workerId){
    this.workerService.getWorkerById(workerId).subscribe(
      data => {
        var r = data;

        this.workerId = workerId;
        this.addForm = this.fb.group({
          agencyId: [this.agencyId],
          assignmentCode: [r.assignmentCodeId, Validators.required],
          email: [r.email, Validators.required],
          firstname: [r.firstname, Validators.required],
          dob: [r.dob, Validators.required],
          gender: [r.gender, Validators.required],
          nationality: [r.nationality, Validators.required],
          lastname: [r.lastname, Validators.required],
          phoneNumber: [r.phoneNumber, Validators.required],
          username: [r.username, Validators.required]
        });

        this.new = false;
        this.existing = true;
        this.formArea = true;
        this.getJob();

        this.modalService.dismissAll();
      }
    );
  }

  loginUser(form: FormGroup) {
    this.msg = '';

    if (form.valid) {
      this.authService.userLogin(form.value).subscribe(
        resp => {
            if (!resp.workerId) {
              this.toast.error("You are not authorized to access this resource.");
              this.router.navigate(['login']);
              this.modalService.dismissAll();
              return false;
            }
            this.getWorker(resp.workerId);
            this.modalService.dismissAll();


        },
        err => {
          if (err.status == 0) {
            this.toast.error('Network Connection Failure');
          } else if (err.error.message.includes("Bad credentials")) {
            this.toast.error("Invalid Credentials");
          } else if (err.error.message) {
            this.toast.error(err.error.message);
          } else if (err.status == 500) {
            this.toast.error('Internal Server Error');
          } else {
            this.toast.error('Login Failed: ' + err.status);
          }
        }
      );
    } else {
      this.toast.warning('Enter Credentials.');
    }
  }

  resetPage(){
    this.modalService.dismissAll();
    this.router.navigate(['reset-password']);
  }

  getJob(){
    this.transportService.findById(this.transportId).subscribe(
      data => {
        this.logForm.patchValue(data);
        this.cleaningLogForm.patchValue(data.vehicleLog);
        console.log(data.teamLeader.id,this.workerId);
        if(data.teamLeader.id!=this.workerId){
          this.toast.error("You are not authorized to access this resource.");
          this.router.navigate(['login']);
        }
        this.job = data;
        this.authorized = true;
      }
    );
  }

  updateTab($event){
    this.tabindex  = $event;
    if(this.tabindex>5)this.tabindex =5;
  }

  updateForm($event){
    this.logForm  = $event;
    if(this.tabindex<-1)this.tabindex =1;
  }


  updateCleaningForm($event){
    this.cleaningLogForm  = $event;
    if(this.tabindex<0)this.tabindex =0;
  }

  selectTab(ref: number) {
    this.tabindex = ref;
  }
}
