<form [formGroup]="form" (ngSubmit)="updateVehicle()">
  <div class="d-flex">
    <h5>Rates</h5>
    <button *ngIf="!form.enabled" type="button" class="btn-primary btn ml-auto" (click)="enableEdit()">Edit</button>
  </div>
  <div class="">
    <div class="">
      <div class="row">

        <div class="col-12">
          <p>A vehicle can only use one type of rate. Please select your rating type for this vehicle.</p>
           <form action="">
            <div class="row">
              <div class="btn-group col-4">
                <h4><input type="radio" [(ngModel)]="rateType" value="D" class="form-" name="rateType">Daily Rate </h4>
              </div>
              <div class="btn-group col-4">
                <h4><input type="radio" [(ngModel)]="rateType" value="H" class="form-" name="rateType">Hourly Rate </h4>
              </div>
            </div>
          </form>

        <div *ngIf="rateType=='D'" class="form-group row">
          <div *ngFor="let r of ratesArray.controls; let i = index; trackBy: track" class="col-md-3">
            <form [formGroup]="r"  >
              <label>{{r.value.desc}}</label>
              <input type="number" maxlength="255" formControlName="rate" placeholder="£0.00" class="form-control small-input">
            </form>
          </div>
        </div>

        <div *ngIf="rateType=='H'" class="form-group row">
          <div class="col-md-3">

              <label>Hourly Rate (£/hr)</label>
              <input type="number" maxlength="255" formControlName="hourlyRate" placeholder="£0.00" class="form-control small-input">

          </div>
          <div class="col-md-3">

              <label>Mileage Rate (£/mile)</label>
              <input type="number" maxlength="255" formControlName="mileageRate" placeholder="£0.00" class="form-control small-input">

          </div>
        </div>

      </div>
      <div class="col-12">
        <h4>Deposit Required</h4>
        <div class="form-group">
          <input type="number" maxlength="11" formControlName="depositAmt" placeholder="$0.00"class="form-control small-input">
        </div>
      </div>
      <div class="col-12">
        <h4>Mileage allowance per day</h4>
        <div class="form-group">
          <select  class="form-control small-input" placeholder="" formControlName="maxDailyMileage">
            <option value="" disabled>Mileage allowance</option>
            <option value="100">100km</option>
            <option value="200">200km</option>
            <option value="unlimited">Unlimited</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-1 btn-group">
  <button *ngIf="form.enabled" type="button" (click)="disableForm()" class="btn-danger mr-1 btn">Cancel</button>
  <button *ngIf="form.enabled" type="submit" class="btn-primary btn">Submit</button>
</div>
</form>
