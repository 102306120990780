<div class="">
  <div [class]="!teamLeaderView ? 'modal-body' : ''">
    <section>
      <div>
        <h4>Job Planner</h4>
      </div>
      <div class="card p-1 bg-primary-custom">Enter actual shift start and end times for each worker below</div>

      <div class="mt-4 row">
        <div *ngFor="let r of selectedJob?.transportWorkerSpecList" class="col-lg-6">

          <div *ngIf="r.bookings?.length >= 1" class="row mb-4">
            <div class="col-md-5">
              <h5>{{ r.assignmentCodeName }}</h5>
            </div>
            <div class="col-md-12 row"> <!-- Changed from col-md-7 to col-md-12 to make it span full width -->
              <div class="col-6"><h6>Actual Start time</h6></div>
              <div class="col-6"><h6>Actual End time</h6></div>
            </div>

            <div *ngFor="let b of r.bookings; index as i" class="col-12">
              <div *ngIf="b.shiftStatus != 'APPLIED' && b.shiftStatus != 'CANCELLED'" class="text-left row">
                <div class="col-md-5">
                  <p>{{ b.worker }}</p>
                </div>
                <div class="col-md-12 row"> <!-- Changed from col-md-7 to col-md-12 to make it span full width -->
                  <div class="col-6">
                    <input
                      type="datetime-local"
                      (change)="setTime($event, b.id)"
                      class="form-control"
                      value="{{ b.shiftDate }}"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="datetime-local"
                      (change)="setEndTime($event, b.id)"
                      class="form-control"
                      value="{{ b.shiftEndDate }}"
                    />
                  </div>
                </div>
              </div>

              <hr class="w-100 col-12" />
            </div>
          </div>
        </div>

        <div class="col-12">
          <button type="submit" class="btn btn-primary" (click)="uploadWorkerTimes()">Complete</button>
        </div>
      </div>
    </section>
  </div>
</div>
