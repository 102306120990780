<main class="mt-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-8 text-left">
          <h3>Update Shift Details</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-4 text-left"></div>
      </div>
    </div>

    <section class="content mt-4" *ngIf="shift">
      <form [formGroup]="editForm" (ngSubmit)="updateShift(editForm)">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                Flexi-Worker Assignment Code

                <input
                type="text"
                disabled=""
                placeholder="Date"
                value="{{shift?.assignmentCode}}"
                class="form-control"

              />

              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                Gender
                <select  disabled class="form-control">
                  <option value="MALE"   [selected]=" shift.gender=='MALE' ">Male</option>
                  <option value="FEMALE"  [selected]=" shift.gender=='FEMALE' ">Female</option>
                  <option value="NO_PREFERENCE"  [selected]=" shift.gender=='NO_PREFERENCE' ">No Preference</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                Shift Type
                <div class="form-group">
                  <input type="text" maxlength="255"   disabled class="form-control" name="" id="" [value]=" shift.shiftType " aria-describedby="helpId" placeholder="">
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="form-group">
                Start
                <input
                type="datetime-local"
                placeholder="Date"
                formControlName="start"
                class="form-control"

              />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="form-group">
                End
                <input
                  type="datetime-local"
                  placeholder="Date"
                  formControlName="end"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="form-group">
                <label> Break Time</label>
                <div class="input-group">
                  <input
                  type="number"
                  placeholder="Minutes"
                  formControlName="breakTime"
                  class="form-control"
                />
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12">
              <div class="form-group">
                Reply
                <textarea
                  formControlName="reply"
                  placeholder="Reply...(200 characters)"
                  cols="30"
                  rows="5"
                  class="form-control"
                  maxlength="200"
                ></textarea>
              </div>
            </div>
            <!--div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                Show Notes To Agency
                <input
                  type="checkbox"
                  formControlName="showNoteToAgency"
                  class="ml-2"
                  [checked]="shift.showNoteToAgency"
                />
              </div>
            </div-->
            <!--div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                Show Notes To Flexi-Worker
                <input
                  type="checkbox"
                  formControlName="showNoteToFw"
                  class="ml-2"
                  [checked]="shift.showNoteToFw"
                />
              </div>
            </div-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group text-danger-custom danger">
                Did not attend
                <input
                  type="checkbox"
                  formControlName="noneAttendance"
                  class="ml-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid text-center mt-5">
          <button
            type="submit"
            class="ml-4 btn btn-danger"
          >
            <i class="bi bi-x"></i>&nbsp;&nbsp;Cancel
          </button>
          <button
            type="submit"
            class="ml-4 btn btn-success"
          >
            <i class="bi bi-plus-lg"></i>&nbsp;&nbsp;Update Shift
          </button>
        </div>
      </form>
    </section>
  </main>
