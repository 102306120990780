import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AssignmentCodeService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';
  userType: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
  }

  getPaginatedAssignmentCodes(page, size): Observable<any> {
    if(this.userType == 'admin'){
      return this.http.get(`${this.base_url}/assignment-codes/${page}/${size}`);
    }
    else if(this.userType == 'agency'){
      return this.http.get(`${this.base_url}/assignment-code-agency/${this.storageService.decrypt(localStorage.getItem('agentId'))}`);
    }
    else if(this.userType == 'client'){
      return this.http.get(`${this.base_url}/assignment-code-client/${this.storageService.decrypt(localStorage.getItem('clientId'))}`);
    }
  }

  getGuestPaginatedAssignmentCodes(agencyId): Observable<any> {

      return this.http.get(`${this.base_url}/assignment-code-agency/${agencyId}`);

  }

  getPaginatedAgencyAssignmentCodes(agency): Observable<any> {
      return this.http.get(`${this.base_url}/assignment-code-agency/${agency}`);
  };

  createAssignmentCodes(data): Observable<any> {
    return this.http.post(`${this.base_url}/assignment-code`, data);
  }

  updateAssignmentCodes(data): Observable<any> {
    return this.http.put(`${this.base_url}/assignment-code`, data);
  }

  getAssignmentCodesById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/assignment-codes/${ref}`);
  }

  deleteAssignmentCodes(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/assignment-code/${ref}`);
  }


  getPaginatedAssignmentCodesRate(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/assignment-codes-rates/${page}/${size}`);
  }

  createAssignmentCodesRate(data): Observable<any> {
    return this.http.post(`${this.base_url}/assignment-code-rate`, data);
  }

  updateAssignmentCodesRate(data): Observable<any> {
    return this.http.put(`${this.base_url}/assignment-code-rate`, data);
  }

  getAssignmentCodesRateById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/assignment-codes-rate/${ref}`);
  }

  deleteAssignmentCodesRate(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/assignment-code-rate/${ref}`);
  }

  getAgencyAssignmentCodesRate(ref, day): Observable<any> {
    if(day){
      return this.http.get(`${this.base_url}/assignment-code-rates-agency/${ref}?day=${day}`);
    }

    return this.http.get(`${this.base_url}/assignment-code-rates-agency/${ref}`);
  }

}
