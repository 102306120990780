import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class TransportService {


  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';
  userType: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.userType = this.authService.getUserType();
   }



  getPaginatedTransportsByStatus(page, size, status): Observable<any> {

    if(this.userType == 'admin'){
      return this.http.get(`${this.base_url}/transport/${status}/${page}/${size}`);
    }
    else if(this.userType == 'agency'){
      return this.http.get(`${this.base_url}/transport/agency/${this.storageService.decrypt(localStorage.getItem('agentId'))}/${status}/${page}/${size}`);
    }
    else if(this.userType == 'client'){
      return this.http.get(`${this.base_url}/transport/client/${this.storageService.decrypt(localStorage.getItem('clientId'))}/${status}/${page}/${size}`);
    }
    else if(this.userType == 'worker'){
      return this.http.get(`${this.base_url}/transport/worker/${this.storageService.decrypt(localStorage.getItem('workerId'))}/${status}/${page}/${size}`);
    }

  }

  getPaginatedClientTransportsByStatus(page, size, status, client): Observable<any> {

    return this.http.get(`${this.base_url}/transports-clients-status/${page}/${size}/${client}/${status}`);

  }

  getAgencyReleasedTransports(agenyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/agency-transports-released/${agenyId}/${page}/${size}`);
  }

  getDriverJobs(driverId:number, status:String, page:number, size:number): Observable<any> {
    return this.http.get(`${this.base_url}/transport/worker/driver/${driverId}/${status}/${page}/${size}`);
  }

  createTransport(data): Observable<any> {
    return this.http.post(`${this.base_url}/transport/`, data);
  }

  updateTransport(data): Observable<any> {
    return this.http.put(`${this.base_url}/transport`, data);
  }


  bookWorker(workerId, specId): Observable<any> {
    return this.http.post(`${this.base_url}/transport-booking/book/${workerId}/${specId}`,{});
  }

  commitJob(transportId): Observable<any> {
    return this.http.put(`${this.base_url}/transport/commit/${transportId}`,{});
  }

  findById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/transport/${ref}`);
  }


  uploadWorkerTimes(ref): Observable<any> {
    return this.http.put(`${this.base_url}/transport/worker-times`, ref);
  }


  findVehicleById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/asset/${ref}`);
  }

  uploadRisk(file: File, transportId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('transportId', transportId);

    return this.http.post(`${this.base_url}/transport/upload-risk-document/${transportId}`, formData);


  }


  cancelTransport(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/transport/${ref}`);
  }

  authorizeTransport(data): Observable<any> {
    return this.http.put(`${this.base_url}/transport/authorize`, data);
  }

  updateMileage(data): Observable<any> {
    return this.http.put(`${this.base_url}/transport/mileage`, data);
  }

  updateVehicle(data): Observable<any> {
    return this.http.put(`${this.base_url}/asset`, data);
  }

  updateVehicleRates(data): Observable<any> {
    return this.http.put(`${this.base_url}/vehicle/rates`, data);
  }

  updateVehicleCheck(data): Observable<any> {
    return this.http.put(`${this.base_url}/transport/vehicle-check`, data);
  }

  updateCleaningCheck(data): Observable<any> {
    return this.http.put(`${this.base_url}/transport/cleaning`, data);
  }

  rateProvider(transport): Observable<any> {
    return this.http.put(`${this.base_url}/transport/rate/${transport}`, null);
  }

  bookTransport(transport, worker, agency): Observable<any> {
    return this.http.put(`${this.base_url}/book-transport/${transport}/${worker}/${agency}`, null);
  }

  saveInventoryUrl(file: File[], vehicleId,inventoryName,dateInstalled,nextCheckDate, inventoryId ): Observable<any> {
    const formData: FormData = new FormData();
    //if(file!=null) formData.append('file', file, file.name);
    if(inventoryId!=null)formData.append('inventoryId', inventoryId);
    formData.append('inventoryName', inventoryName);
    formData.append('vehicleId', vehicleId);
    formData.append('dateInstalled', dateInstalled);
    formData.append('nextCheckDate', nextCheckDate);

    return this.http.post(`${this.base_url}/asset/addons/inventory`, formData);
  }


  uploadVehicleImage(file: File[], vehicleId): Observable<any> {
    const formData: FormData = new FormData();
    console.log(file);

    for (const f of file) {
        formData.append("file", f);
        console.log(f);
    }
    formData.append('vehicleId', vehicleId);

    return this.http.post(`${this.base_url}/asset/addons/photo`, formData);
  }


  saveDocument(file: File, form ): Observable<any> {
    const formData: FormData = new FormData();
    console.log(file);
    formData.append('file', file, file.name);
    if(form.value.inventoryId!=null)formData.append('inventoryId', form.value.inventoryId);
    formData.append('docName', form.value.docName);
    formData.append('vehicleId', form.value.vehicleId);
    formData.append('expiryDate', form.value.expiryDate);

    return this.http.post(`${this.base_url}/asset/addons/document`, formData);
  }

  newBookTransport(transport, worker): Observable<any> {
    return this.http.put(`${this.base_url}/direct-book-transport/${transport}/${worker}`, null);
  }

  setMain(id): Observable<any> {
    return this.http.put(`${this.base_url}/asset/addons/set-main-photo/${id}`, null);
  }

  deletePhoto(id): Observable<any> {
    return this.http.delete(`${this.base_url}/asset/addons/photo/${id}`);
  }


  getAgencyTransportApplicants(transportId, agencyId): Observable<any> {
    return this.http.get(`${this.base_url}/applicants/${transportId}/${agencyId}`);
  }

  getVehicleLogs(vehiceId: number, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/asset/${vehiceId}/APPROVED/${page}/${size}`);
  }

  getTransportApplicants(transportId): Observable<any> {
    return this.http.get(`${this.base_url}/transport-applicants/${transportId}`);
  }

  approveAppliedTransport(transport, worker): Observable<any> {
    return this.http.put(`${this.base_url}/approve-applied-transport/${transport}/${worker}/`, null);
  }

  uploadDamageReport(file: File,logId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${this.base_url}/vehicle-log/upload-damage-report/${logId}`, formData);
  }




  getPaginatedTransportDirectorates(page, size): Observable<any> {
    if(this.userType == "client"){
      return this.http.get(`${this.base_url}/transport-directorates-client/${this.storageService.decrypt(localStorage.getItem('clientId'))}/${page}/${size}`);
    }
    else if(this.userType == "agency"){
      return this.http.get(`${this.base_url}/transport-directorates-agency/${this.storageService.decrypt(localStorage.getItem('agentId'))}/${page}/${size}`);
    }
    else{
      return this.http.get(`${this.base_url}/transport-directorates/${page}/${size}`);
    }

  }

  getPaginatedAgencyTransportDirectorates(agency, page, size): Observable<any> {
      return this.http.get(`${this.base_url}/transport-directorates-agency/${agency}/${page}/${size}`);
  }

  getPaginatedClientDirectorates(page, size, client): Observable<any> {
    return this.http.get(`${this.base_url}/transport-directorates-client/${client}/${page}/${size}`);
  }

  createTransportDirectorate(data): Observable<any> {
    return this.http.post(`${this.base_url}/transport-directorate`, data);
  }

  updateTransportDirectorate(data): Observable<any> {
    return this.http.put(`${this.base_url}/transport-directorate`, data);
  }

  getTransportDirectorateById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/transport-directorate/${ref}`);
  }

  getTransportDirectorateByLocation(ref): Observable<any> {
    return this.http.get(`${this.base_url}/transport-directorate-by-location/${ref}`);
  }

  deleteTransportDirectorate(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/transport-directorate/${ref}`);
  }



  getPaginatedTransportLocations(page, size): Observable<any> {
    if(this.userType == "client"){
      return this.http.get(`${this.base_url}/transport-location-client/${this.storageService.decrypt(localStorage.getItem('clientId'))}`);
    }
    else if(this.userType == "agency"){
      return this.http.get(`${this.base_url}/transport-location-agency/${this.storageService.decrypt(localStorage.getItem('agentId'))}/${page}/${size}`);
    }
    else{
      return this.http.get(`${this.base_url}/transport-locations/${page}/${size}`);
    }

  }

  createTransportLocation(data): Observable<any> {
    return this.http.post(`${this.base_url}/transport-location`, data);
  }

  updateTransportLocation(data): Observable<any> {
    return this.http.put(`${this.base_url}/transport-location`, data);
  }

  getTransportLocationById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/transport-location/${ref}`);
  }

  getTransportLocationByClient(ref): Observable<any> {
    return this.http.get(`${this.base_url}/transport-location-client/${ref}`);
  }

  deleteTransportLocation(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/transport-location/${ref}`);
  }



  getPaginatedTransportTypes(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/transport-types/${page}/${size}`);
  }

  createTransportType(data): Observable<any> {
    return this.http.post(`${this.base_url}/transport-type`, data);
  }



  pickTeamLeader(id:number,transportId:number): Observable<any> {
    return this.http.put(`${this.base_url}/transport/pick-team-leader/${id}/${transportId}`, {});
  }
  pickDriver(id:number,transportId:number): Observable<any> {
    return this.http.put(`${this.base_url}/transport/pick-driver/${id}/${transportId}`, {});
  }
  pickVehicle(id:number,transportId:number): Observable<any> {
    return this.http.put(`${this.base_url}/transport/vehicle/${id}/${transportId}`, {});
  }

  //Team Leader Update
  teamLeaderUpdate(data): Observable<any> {
    return this.http.put(`${this.base_url}/transport/team-leader/entry`, data);
  }





  updateTransportType(data): Observable<any> {
    return this.http.put(`${this.base_url}/transport-type`, data);
  }

updateTeamLeader(data): Observable<any> {
    return this.http.put(`${this.base_url}/transport/team-leader/entry`, data);
}

  getTransportTypeById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/transport-type/${ref}`);
  }

  deleteTransportType(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/transport-type/${ref}`);
  }

}
