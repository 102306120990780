import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AssignmentCodeService } from 'src/app/shared/services/assignment-code.service';
import { ShiftService } from 'src/app/shared/services/shift.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-edit-shift',
  templateUrl: './edit-shift.component.html',
  styleUrls: ['./edit-shift.component.css']
})
export class EditShiftComponent implements OnInit {

  // startDate:any;

  startDate:any;
  endDate:any;

  shiftId: number;
  shift: any;/* Needed */
  types: any;
  assCodes: any;/* Needed */
  locations: any;
  directorates: any;

  editForm: FormGroup;
  searchCriteria: string = null;
  locationFilter: number = null;

  constructor(
    private activateRoute: ActivatedRoute,
    private toast: ToastrService,
    private shiftService: ShiftService,
    private fb: FormBuilder,
    private router: Router,
    private assignmentService: AssignmentCodeService,
    public datepipe: DatePipe
  ) {
    this.editForm = this.fb.group({
      id:                       '',
      reply:                      '',
      start:                  '',
      end:               '',
      assignmentCode:               '',
      breakTime:               '',
      showNoteToAgency:           '',
      showNoteToFw:               '',
      noneAttendance: ''
    });
   }

  ngOnInit(): void {

    this.getCodes();

    this.activateRoute.params.subscribe((params) => {
      this.shiftId = params["id"];

      this.getShift();
    });

    this.getTypes();
    this.getLocations();
    this.getDirectorates();




  }

  getTypes(){
    this.shiftService.getPaginatedShiftTypes(0, 100).subscribe(
      data => {
        // console.table(data.content);
        this.types = data.content;
      }
    );
  }

  getCodes(){
    this.assignmentService.getPaginatedAssignmentCodes(0, 100).subscribe(
      data => {
        // console.table(data);

        this.assCodes = data;
      }
    );
  }

  getShift(){
    this.shiftService.getShiftById(this.shiftId).subscribe(
      resp => {
        this.shift = resp;
        this.loadForm(resp);
      }
    );
  }

  updateShift(form: FormGroup){

    if(form.valid){
      this.shiftService.updateShift(form.value).subscribe(
        resp => {
          console.table(resp);

          this.toast.success('Shift Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  loadForm(shift){

      this.editForm = this.fb.group({
        id: [shift.id, Validators.required],
        breakTime: [shift.breakTime, Validators.required],
        reply: [shift.reply,],
        start: [shift.start, Validators.required],
        end: [shift.end, Validators.required],
        showNoteToAgency: [shift.showNoteToAgency??false, Validators.required],
        showNoteToFw: [shift.showNoteToFw??false, Validators.required],
        noneAttendance: [false, Validators.required]
      });

  }

  getTypeId(ref){
    let lid;

    if(ref){
      this.types.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

  getAssCode(ref){
    let lid;
    const codeArr = this.assCodes;
    if(ref){
      for (let index = 0; index < codeArr.length; index++) {
        const element = codeArr[index];
        codeArr.forEach(element => {
          if(element.code == ref){
            lid = element.id;
          }
        });
      }
    }
    return lid;
  }

  getLocations(){
    this.shiftService.getPaginatedShiftLocations(0, 100).subscribe(
      data => {
        // console.table(data);

        this.locations = data;
      }
    );
  }

  getDirectorates(){
    this.shiftService.getPaginatedShiftDirectorates(this.searchCriteria, this.locationFilter, 0, 100).subscribe(
      data => {
        // console.table(data.content);

        this.directorates = data.content;
      }
    );
  }

  getLocationId(ref){
    let lid;

    if(ref){
      this.locations?.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

  formatBreak(time){
    const myArray = time.split("hr");
    const myArray2 = myArray[1].split("mins");
    const myArray3 = [myArray[0], myArray[1]];
    console.log(myArray3);
  }

  getDirectorateId(ref){
    let lid;

    if(ref){
      this.directorates?.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

}
