<table class="table table-striped mb-0 styled-table text-center">
    <thead class="">
        <tr class="text-center">
            <th>Shift Id</th>
            <th>Shift Date</th>
            <th>Time</th>
            <th>Assignment<br>Code</th>
            <th *ngIf="userType == 'admin'">Directorate</th>
            <th *ngIf="userType != 'client'">Client Name</th>
          <th *ngIf="userType == 'client'">Directorate</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let s of shifts | shiftsFilter: agencyFilter :clientFilter :startDateFilter :endDateFilter :directorateFilter;">
            <td>{{s.id}}</td>
            <td>{{s.start | date:'dd/MM/yyy'}}</td>
            <td>{{s.start | date:'HH:mm'}} - {{s.end | date:'HH:mm'}}</td>
            <td>{{s.assignmentCode}}</td>
            <th *ngIf="userType == 'admin'">{{s.directorate}}</th>
            <td *ngIf="userType == 'client'">{{s.directorate}}</td>
          <td *ngIf="userType != 'client'">{{s.client}}</td>
            <td>
                <span class="mr-3 text-primary-custom pointer"  (click)="viewDetails(s.id, infoNew)">
                    <i class="fa fa-eye" title="view"></i>
                </span>
                <!-- <span class="mr-3 text-success pointer" *ngIf="userType == 'client'" (click)="editDetails(s.id)">
                  <i class="fa fa-pencil-alt" title="edit"></i>
                </span> -->
                <span class="mr-3 text-danger-custom pointer" *ngIf="userType != 'admin'&&'UPDATE_SHIFT'|permission" (click)="loadAgency(s.id, delNew)">
                  <i class="fa fa-trash-alt" title="delete"></i>
              </span>
            </td>
        </tr>
    </tbody>
  </table>
  <section class="pagination-container" *ngIf="shifts">
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
          Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
        </div>
        <div class="col-12 col-sm-6 col-md-4 text-right">
          <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
            <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
          </button>
        </div>
        <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
          <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
            Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
          </button>
        </div>
      </div>
    </div>
  </section>

  <!-- Modals -->
<ng-template class="modal fade" #delNew let-modal>
  <div class="">
      <div class="modal-header bg-danger text-white">
          <h5 class="modal-title text-center w-100">Delete NEW Shift</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">
          <div class="bg-modal card-body">
              <div class="text-center">
                  <h3>You are about to delete this New Shift?</h3>
              </div>
          </div>
          <div align="right" class="mt-3">
            <button class="btn btn-info btn-sm mr-3" (click)="modal.dismiss()">
                Cancel
            </button>
            <button class="btn btn-sm btn-danger" (click)="deleteShift()">
                Delete Shift
            </button>
          </div>
      </div>
  </div>
</ng-template>

<ng-template class="modal fade" #infoNew let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center w-100">View Shift</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">
          <div class="bg-modal card-body">
              <div class="container">
                <div class="row">

                  <div class="col-12 col-md-6 p-5">
                    <p class="text">
                      Id
                      <b class="d-block float-right">{{shiftInfo.id}}</b>
                    </p>
                    <p class="text">
                      Date
                      <b class="d-block float-right">{{shiftInfo.shiftDate}}</b>
                    </p>
                    <p class="text">
                      End Date
                      <b class="d-block float-right">{{shiftInfo.shiftEndDate}}</b>
                    </p>
                    <p class="text">
                      Time
                      <b class="d-block float-right">{{shiftInfo.shiftStartTime}} - {{shiftInfo.shiftEndTime}}</b>
                    </p>
                    <!-- <p class="text">
                      End Time
                      <b class="d-block float-right">{{shiftInfo.shiftEndTime}}</b>
                    </p> -->
                    <p class="text">
                      Break Time
                      <b class="d-block float-right">{{shiftInfo.breakTime}}</b>
                    </p>
                    <p class="text">
                      Shift Type
                      <b class="d-block float-right">{{shiftInfo.shiftType}}</b>
                    </p>
                    <!-- <p class="text">
                      Hours Before Broadcast
                      <b class="d-block float-right">{{shiftInfo.hoursBeforeBroadcasting}}</b>
                    </p>
                    <p class="text">
                      Requires Worker Application
                      <b class="d-block float-right">{{shiftInfo.requireApplicationByWorkers}}</b>
                    </p> -->
                    <p class="text">
                      Status
                      <b class="d-block float-right">{{shiftInfo.shiftStatus}}</b>
                    </p>
                  </div>

                  <div class="col-12 col-md-6 mt-3 mt-md-0 p-5">
                    <p class="text">
                      Location
                      <b class="d-block float-right">{{shiftInfo.shiftLocation}}</b>
                    </p>
                    <p class="text">
                      Directorate
                      <b class="d-block float-right">{{shiftInfo.directorate}}</b>
                    </p>
                    <p class="text">
                      Gender
                      <b class="d-block float-right">{{shiftInfo.gender}}</b>
                    </p>
                    <p class="text">
                      Assignment Code
                      <b class="d-block float-right">{{shiftInfo.assignmentCode}}</b>
                    </p>
                    <p class="text">
                      Client
                      <b class="d-block float-right">{{shiftInfo.client}}</b>
                    </p>
                    <p class="text" *ngIf="userType != 'agency'">
                      Agency
                      <b class="d-block float-right">{{shiftInfo.agency}}</b>
                    </p>
                    <!-- <p class="text">
                      Show Notes to Workers
                      <b class="d-block float-right">{{shiftInfo.showNoteToFw}}</b>
                    </p>
                    <p class="text">
                      Show Notes to Agencies
                      <b class="d-block float-right">{{shiftInfo.showNoteToAgency}}</b>
                    </p> -->
                    <p class="text" *ngIf="userType == 'agency'">
                      Phone Number
                      <b class="d-block float-right">{{shiftInfo.phoneNumber}}</b>
                    </p>
                    <p class="text" *ngIf="userType == 'agency'">
                      Post Code
                      <b class="d-block float-right">{{shiftInfo.postCode}}</b>
                    </p>
                  </div>

                  <section *ngIf="showNotes" class="row col-12">
                    <div class="col-12">
                      Shift Notes
                      <hr>
                    </div>

                    <div class="col-12 bg-white pt-3 myNotes">
                      {{shiftInfo.notes}}
                    </div>
                  </section>
                </div>
              </div>
          </div>
      </div>
  </div>
</ng-template>
