<div class="">
  <div class="modal-body">
    <div class="bg-modal card-body">
      <div class="container-fluid">
        <div class="btn-group">
          <div style="align-self: center;">
            <img style="width: 150px;" src="{{job?.mainPhoto}}" alt="vehicle" onerror="this.src='/assets/images/securetrans.jpg'" />
          </div>
          <div class=" p-2 m-4 card bg-grey ">
            <div class="row">
              <div class="col-md-4">
                <b>{{job?.name}} {{job?.model}}</b><br>
                {{job?.regno}}
              </div>
              <div class="col-md-4">
                <b>Current Mileage</b><br>
                {{job?.mileage}} Miles
              </div>
              <div class="col-md-4">
                <b>Total Jobs</b><br>
                {{job?.jobCount}}
              </div>
            </div>
          </div>

        </div>


        <div class="row text-center mx-0 my-1">
          <div class="col-4  pointer col-md-2 " (click)="selectTab('about')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'about','bg-custom-unselected': selectedTab != 'about'}" style="border-top-left-radius: 5px;">About
          </div>
          <div class="col-4 col-md-2 pointer " (click)="selectTab('docs')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'docs','bg-custom-unselected': selectedTab != 'docs'}">Documents
          </div>
          <div class="col-4 col-md-2 pointer " (click)="selectTab('inventory')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'inventory','bg-custom-unselected': selectedTab != 'inventory'}">Inventory
          </div>
          <div class="col-4 col-md-2 pointer " (click)="selectTab('logs')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'logs','bg-custom-unselected': selectedTab != 'logs'}">Logs
          </div>
          <div class="col-4  pointer col-md-2 " (click)="selectTab('rates')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'rates','bg-custom-unselected': selectedTab != 'rates'}" style="border-top-left-radius: 5px;">Rates
          </div>
          <div class="col-4 col-md-2 pointer " (click)="selectTab('photos')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'photos','bg-custom-unselected': selectedTab != 'photos'}">Photos
          </div>
        </div>




        <div class="mt-2">



          <app-about-vehicle-tab
          *ngIf="selectedTab=='about'"
          [form]="logForm"
          [job]="job"
          [tabindex]="selectedTab"
          (tabindexO)="updateTab($event)"
          ></app-about-vehicle-tab>


          <app-rates-tab
          *ngIf="selectedTab=='rates'"
          [form]="logForm"
          [job]="job"
          [tabindex]="selectedTab"
          (tabindexO)="updateTab($event)"
          ></app-rates-tab>


          <app-vehicle-documents-tab
          *ngIf="selectedTab=='docs'"
          [job]="job"
          [tabindex]="selectedTab"
          (tabindexO)="updateTab($event)"
          ></app-vehicle-documents-tab>




            <app-vehicle-inventory-tab
            *ngIf="selectedTab=='inventory'"
            [job]="job"
            [tabindex]="selectedTab"
            (tabindexO)="updateTab($event)"
            ></app-vehicle-inventory-tab>


            <app-view-vehicle-tab4
            *ngIf="selectedTab=='logs'"
          [job]="job"
          [tabindex]="selectedTab"
          (tabindexO)="updateTab($event)"
            ></app-view-vehicle-tab4>


            <app-photos-tab
            *ngIf="selectedTab=='photos'"
          [form]="logForm"
          [job]="job"
          [tabindex]="selectedTab"
          (tabindexO)="updateTab($event)"
            ></app-photos-tab>




        </div>
        <br>
      </div>
    </div>
  </div>
</div>


