import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { AgencyJobsModule } from 'src/app/agency/agency-jobs/jobs.module';


import { TeamLeaderLogin } from './transport-components/team-leader-login/team-leader-login.component';
import { LeaderEntryComponent } from './transport-components/lead-entry/leader-entry.component';
import { ViewTransportTabComponent } from './transport-components/lead-entry/view/view-transport-tab.component';
import { LeaderEntry3Component } from './transport-components/lead-entry/feedback/leader-entry3.component';
import { LeaderEntry2Component } from './transport-components/lead-entry/medication/leader-entry2.component';
import { LeaderEntry1Component } from './transport-components/lead-entry/property/leader-entry1.component';
import { TeamLeaderViewJobComponent } from './transport-components/log-transport-job.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { PipesModule } from "../../shared/pipes.module";
import { WorkerTransportsModule } from 'src/app/worker/transport/worker-transport.module';
import { JobPlanTabComponent } from 'src/app/agency/agency-jobs/agency-jobs-list/components/job-plan-tab/job-plan-tab';

@NgModule({
  declarations: [JobPlanTabComponent, TeamLeaderViewJobComponent,TeamLeaderLogin, LeaderEntryComponent, LeaderEntry1Component, LeaderEntry2Component, LeaderEntry3Component,ViewTransportTabComponent],
  imports: [
    CommonModule,
    AgencyJobsModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,
    NgSelectModule,
    MatSliderModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    PipesModule,
    WorkerTransportsModule
],
  exports:[
    LeaderEntryComponent, LeaderEntry1Component, LeaderEntry2Component, LeaderEntry3Component
  ],
  providers: [DatePipe]
})
export class GuestTransportsModule { }
