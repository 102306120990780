
  <div class="">
    <div [class]="!teamLeaderView?'modal-body':''">
      <section>
        <div>          <h4>Job Planner</h4>   </div>
          <div class="card p-1 bg-primary-custom"> Enter actual shift start and end times for each worker below</div>

          <div   class="mt-4 row">
            <div *ngFor="let r of selectedJob?.transportWorkerSpecList" class="col-lg-6">

              <div *ngIf="r.bookings?.length>=1" class="row mb-4">
                <div class="col-md-5">
                  <h5 >{{r.assignmentCodeName}}</h5>
                </div>
                <div class="col-md-7 row">
                  <div class="col-6"><h6 >Start time</h6></div>
                  <div class="col-6"><h6 >End time</h6></div>
                </div>

                <div *ngFor="let b of r.bookings;  index as i" class="col-12">
                  <div *ngIf="b.shiftStatus!='APPLIED'&&b.shiftStatus !='CANCELLED'" class=" text-left  row">
                    <div class="col-md-5 ">
                      <p>{{b.worker}}</p>
                    </div>
                    <div class="col-md-7 row">
                      <div class="col-6">
                        <input type="datetime-local" (change)="setTime($event, b.id)" class="form-control" value="{{b.shiftDate}}">
                      </div>
                      <div class="col-6">
                        <input type="datetime-local" (change)="setEndTime($event, b.id)" class="form-control" value="{{b.shiftEndDate}}">
                      </div>
                    </div>
                  </div>

                  <hr class="w-100 col-12"/>

                </div>

              </div>


            </div>

            <div class="col-12">
              <button type="submit" class="btn btn-primary" (click)="uploadWorkerTimes()">Complete</button>
            </div>

          </div>

      </section>

    </div>
  </div>


  <!-- Modals -->

  <ng-template class="modal fade" #acceptModal let-modal>
    <div class="">
        <div class="modal-header bg-main text-white">
            <h5 class="modal-title text-center w-100">Accept Job</h5>
            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </span>
        </div>
        <div class="modal-body">
            <div class="bg-modal card-body">
                <div class="text-center">
                    <h3>You are about to accept this New Job.</h3>
                </div>
            </div>
            <div align="right" class="mt-3">
              <button class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                  Cancel
              </button>
            </div>
        </div>
    </div>
  </ng-template>





<ng-template class="modal fade" #authModal let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center w-100">Authorize Transport Job</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">

        <form [formGroup]="authorizeForm"  >
          <div class="bg-modal card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <p>This action will authorise this job and workers involved</p>
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Job Id: {{selectedJob.id}}</span>
                  <hr class="bg-black mt-0">
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <Label>Date Transport Is Required</Label>
                    <input type="text" maxlength="255"  disabled  value="{{selectedJob?.dateTimeRequired| date:'dd/MM/yyyy'}}" class="form-control date">
                    <Label>Time Transport Is Required</Label>
                    <input type="text" maxlength="255"  disabled  value="{{selectedJob?.dateTimeRequired| date:'HH:mm'}}" class="form-control date">
                  </div>
                  <div class="form-group">
                    <input type="number" hidden maxlength="255" formControlName="transportId" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="start">Actual Start Date and Time</label>
                    <input type="datetime-local"  maxlength="255" formControlName="start" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="end">Actual End Date and Time</label>
                    <input type="datetime-local"  maxlength="255" formControlName="end" class="form-control">
                  </div>
                  <div class="form-group">
                    <label>Break Time</label>
                    <input type="number" maxlength="255" formControlName="breakTime" placeholder="minutes" class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="right" class="mt-3">
            <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-success">
                Authorize
            </button>
          </div>
        </form>
      </div>
  </div>
  </ng-template>


  <ng-template class="modal fade" #editJobModal let-modal>
    <div class="card">
      <app-create-transport-job [selectedJob]  =  "selectedJob"></app-create-transport-job>
    </div>
  </ng-template>
