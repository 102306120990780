

<div class="">
  <div class="modal-header bg-main text-white">
    <h5 class="modal-title text-center w-100">Driver Log</h5>
          <!-- <span type="button" class="btn close text-white"  aria-label="Close" (click)="closeWin()">
            <span aria-hidden="true">&times;</span>
          </span> -->
  </div>
  <div class="modal-body">
    <div class="bg-modal">
      <div class="">
        <form >
          <div class="">
            <div class="container-fluid">
              <div class="cardForm">
                <!-- <h3>Driver</h3> -->
                <span>Driver:</span>
                <div class="info-box">
                  {{log.worker}}
                </div>
              </div>
              <div class="cardForm">
                <!-- <h3>Vehicle Information</h3> -->
                <div class="row">
                  <div class="col-12">
                    Vehicle:
                    <div class="info-box">
                      {{log.vehicle??"_"}}
                    </div>
                  </div>
                  <div class="col-4">
                    Start Mileage:
                    <div class="info-box">
                      {{log.startMileage??"_"}}
                    </div>
                  </div>
                  <div class="col-4">
                    End Mileage:
                    <div class="info-box">
                      {{log.endMileage??"_"}}
                    </div>
                  </div>
                  <div class="col-4">
                    Total Mileage:
                    <div class="info-box">
                      {{log.endMileage - log.startMileage}}
                    </div>
                  </div>
                </div>

                <div class="row">


                  <!-- <div class="col-4">
                    Total Hrs:
                    <div class="info-box">
                      {{(log.totalMins??0)/60}}
                    </div>
                  </div> -->


                </div>
              </div>
              <div class="cardForm">
                <!-- <h3>Date & Time</h3> -->
                <div class="row">
                  <div class="col-6">
                    Pick up date
                    <div class="info-box">
                      {{log.transport.start | date: 'dd/MM/yyyy'}}
                    </div>
                  </div>
                  <div class="col-6">
                    Pick up time
                    <div class="info-box">
                      {{log.transport.start | date: 'hh:mm'}}
                    </div>
                  </div>

                </div>
              </div>
              <div class="cardForm">
                <!-- <h3>Pick up</h3> -->
                <div class="row">
                  <div class="col-4">
                    Pick up Location:
                    <div class="info-box">
                       {{log.transport.pcaddress}}
                    </div>
                  </div>

                  <div class="col-4">
                    Post Code:
                    <div class="info-box">
                      {{log.transport.pickupPostCode}}
                    </div>
                  </div>

                  <div class="col-4">
                    Phone:
                    <div class="info-box">
                       {{log.transport.pickupLocationContactNumber }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cardForm">
                <!-- <h3>Destination</h3> -->
                <div class="row">
                  <div class="col-4">
                    Destination:
                    <div class="info-box">
                       {{log.transport.destination}}
                    </div>
                  </div>
                  <div class="col-4">
                    Post Code:
                    <div class="info-box">
                       {{log.transport.destinationPostCode}}
                    </div>
                  </div>

                  <div class="col-4">
                    Phone:
                    <div class="info-box">
                       {{log.transport.destinationContactNumber}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-box">


                <strong>Reportable Damage:</strong>
                {{log.damageReport}}<br>
                <a *ngIf="log.damageDoc" (click)="openDoc(log.damageDoc)" target="_blank"> Click To View Damage Report</a>


              </div>
              <div class="info-box">
                Driver Vehicle Check - items checked by driver before and during driving.<br>
                Defects are flagged below.
                <!-- <div class="btn-group">
                    <span class="text-primary-custom material-symbols-outlined">priority</span>
                    <span>Servicable</span>

                    <span class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                    <span>Defect</span>
                </div> -->

              </div>




              <div class="info-box" *ngIf="checkNull([log.indicators,log.lamp,log.sideReapter,log.stoplamp])&&!(log.indicators&&log.lamp&&log.sideReapter&&log.stoplamp)">
                <span *ngIf="(log.indicators&&log.lamp&&log.sideReapter&&log.stoplamp)" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!(log.indicators&&log.lamp&&log.sideReapter&&log.stoplamp)" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Lamps/indicators/side repeaters/stop lamps </span>
                <p>{{damageDescriptions.indicators}}</p>
                <p>{{damageDescriptions.lamp}}</p>
                <p>{{damageDescriptions.sideRepeater}}</p>
                <p>{{damageDescriptions.stopLamp}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.reflectors,log.markers,log.warningdevices])&&!(log.reflectors&&log.markers&&log.warningdevices)">
                <span *ngIf="(log.reflectors&&log.markers&&log.warningdevices)" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!(log.reflectors&&log.markers&&log.warningdevices)" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Reflectors/markers/warning devices</span>
                <p>{{damageDescriptions.reflectors}}</p>
                <p>{{damageDescriptions.markers}}</p>
                <p>{{damageDescriptions.warningdevices}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.mirror,log.windscreen])&&!(log.mirror&&log.windscreen)">
                <span *ngIf="(log.mirror&&log.windscreen)" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!(log.mirror&&log.windscreen)" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Mirrors/windscreen/glass – condition-security</span>
                <p>{{damageDescriptions.mirror}}</p>
                <p>{{damageDescriptions.windscreen}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.brakes])&&!log.brakes">
                <span *ngIf="log.brakes" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!log.brakes" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Brakes – operation-excess travel</span>
                <p>{{damageDescriptions.brakes}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.drivingcontrol])&&!log.drivingcontrol">
                <span *ngIf="log.drivingcontrol" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!log.drivingcontrol" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Driving controls/steering – wear-operation</span>
                <p>{{damageDescriptions.drivingcontrol}}</p>
              </div>

              <div class="info-box"  *ngIf="checkNull([log.tyreInflation])&&!log.tyreInflation">
                <span *ngIf="log.tyreInflation" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!log.tyreInflation" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Tyres – inflation-damage-wear</span>
                <p>{{damageDescriptions.tyreInflation}}</p>
              </div>
              <div class="info-box" *ngIf="checkNull([log.wheelCondition])&&!log.wheelCondition">
                <span *ngIf="log.wheelCondition" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!log.tyreInflation" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Wheels – condition-security</span>
                <p>{{damageDescriptions.tyreInflation}}</p>
              </div>

              <!-- <div class="info-box" *ngIf="checkNull([log.indicators])&&!log.indicators">
                <span *ngIf="log.indicators" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!log.indicators" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Load -security</span>
                <p>{{damageDescriptions.indicators}}</p>
              </div> -->

              <div class="info-box" *ngIf="checkNull([log.horn,log.wipers,log.washers])&&!(log.horn&&log.wipers&&log.washers)">
                <span *ngIf="(log.horn&&log.wipers&&log.washers)" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!(log.horn&&log.wipers&&log.washers)" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Horn/wipers/washers – operation-condition</span>
                <p>{{damageDescriptions.horn}}</p>
                <p>{{damageDescriptions.wipers}}</p>
                <p>{{damageDescriptions.washers}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.fuel,log.oil,log.fluidleaks])&&!(log.fuel&&log.oil&&log.fluidleaks)" >
                <span *ngIf="(log.fuel&&log.oil&&log.fluidleaks)" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!(log.fuel&&log.oil&&log.fluidleaks)" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Fuel/oil/fluid – leaks</span>
                <p>{{damageDescriptions.fuel}}</p>
                <p>{{damageDescriptions.oil}}</p>
                <p>{{damageDescriptions.fluidleaks}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.exhaust])&&!log.exhaust" >
                <span *ngIf="log.exhaust" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!log.exhaust" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Exhaust – condition-smoke-emission</span>
                <p>{{damageDescriptions.exhaust}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.speedometer])&&!log.speedometer">
                <span *ngIf="log.speedometer" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!log.speedometer" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Speedometer – operation</span>
                <p>{{damageDescriptions.speedometer}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.battery])&&!log.battery">
                <span *ngIf="log.battery" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!log.battery" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Battery – security-condition</span>
                <p>{{damageDescriptions.battery}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.seatbelt])&&!log.seatbelt">
                <span *ngIf="log.seatbelt" class="text-primary-custom material-symbols-outlined">priority</span>
                <span *ngIf="!log.seatbelt" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Seat belts – condition-operation</span>
                <p>{{damageDescriptions.seatbelt}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.doors])&&!log.doors">
                <!-- <span *ngIf="log.doors" class="text-primary-custom material-symbols-outlined">priority</span> -->
                <span *ngIf="!log.doors" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span>Doors – condition-operation-security</span>
                <p>{{damageDescriptions.doors}}</p>
              </div>

              <div class="info-box" *ngIf="checkNull([log.adblue])&&!log.adblue">
                <!-- <span *ngIf="log.adblue" class="text-primary-custom material-symbols-outlined">priority</span> -->
                <span *ngIf="!log.adblue" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span >AdBlue® – if required</span>
                <p>{{damageDescriptions.adblue}}</p>

              </div>

              <div class="info-box" *ngIf="checkNull([log.trailercoupling])&&!log.trailercoupling">
                <!-- <span *ngIf="log.trailercoupling" class="text-primary-custom material-symbols-outlined">priority</span> -->
                <span *ngIf="!log.trailercoupling" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                <span >Trailer coupling – operation-condition-security</span>
                <p>{{damageDescriptions.trailercoupling}}</p>

              </div>





              <h5><strong>Daily Cleaning Schedule</strong></h5>


                  <div class="info-box">
                    <span *ngIf="log.litter" class="text-primary-custom material-symbols-outlined">priority</span>
                    <span *ngIf="!log.litter" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                    <span>Remove any visible litter from vehicle</span>
                  </div>

                  <div class="info-box">
                    <span *ngIf="log.seats" class="text-primary-custom material-symbols-outlined">priority</span>
                    <span *ngIf="!log.seats" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                    <span>Clean all seats including base & rails</span>

                  </div>

                  <div class="info-box">
                    <span *ngIf="log.equipment" class="text-primary-custom material-symbols-outlined">priority</span>
                    <span *ngIf="!log.equipment" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                    <span>Clean and check if all equipment is working (note any concerns in "Notes")</span>
                  </div>

                  <div class="info-box">
                    <span *ngIf="log.sanitizer" class="text-primary-custom material-symbols-outlined">priority</span>
                    <span *ngIf="!log.sanitizer" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                    <span>Ensure hand sanitizer dispenser is full</span>
                  </div>

                  <div class="info-box">
                    <span *ngIf="log.cellArea" class="text-primary-custom material-symbols-outlined">priority</span>
                    <span *ngIf="!log.cellArea" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                    <span>Clean cell area (if applicable)</span>
                  </div>
            </div>
          </div>


          <div class="row">

            <div align= "left" class="col-10">
              <button type="button" class="btn btn-primary btn-sm mr-3" (click)="downloadModalAsPDF()">
                Download as pdf
            </button>
             <button *ngIf="log.status=='WAITING_APPROVAL' && userType=='agency'"  type="button" class="btn btn-sm btn-primary mr-4"(click)="authorize()" >
                Authorise
            </button>
            </div>

        </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ng-template class="modal fade" #add let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center w-100">Comment</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">

            <div class="bg-modal card-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12" >
                    <div class="form-group">
                      <input type="text" [(ngModel)]="reviewComment" value="Comment" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div align="right" class="mt-3">
              <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                  Cancel
              </button>
              <button type="button" (click)="comment()" class="btn btn-sm btn-success">
                  Send Comment
              </button>
            </div>

      </div>
  </div>
</ng-template>
