<table class="table table-striped mb-0 styled-table text-center">
    <thead class="">
        <tr class="text-center">
            <th>Id</th>
            <th>Date</th>
            <!-- <th>Date Booked</th> -->
            <th>Log Type</th>
            <th>Driver</th>
            <th *ngIf="transportStatus=='APPROVED'" >Authoriser</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr  *ngFor="let log of logs">
          <td>{{ log.id }}</td>
          <td>{{log.createdDate | date:'dd/MM/yyyy'}}</td>
          <td>{{ log.type }}</td>
          <td>{{ log.worker }}</td>
          <td *ngIf="transportStatus=='APPROVED'" >{{ log.approvedByName }}</td>
          <td>
                <span class="m-1 text-primary-custom pointer" (click)="viewDriverInfo(log, jobInfo)">
                    <i class="fa fa-eye" title="view"></i>
                </span>

            </td>
        </tr>
    </tbody>
  </table>
  <!-- <section class="pagination-container" *ngIf="jobs">
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
          Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
        </div>
        <div class="col-12 col-sm-6 col-md-4 text-right">
          <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
            <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
          </button>
        </div>
        <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
          <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
            Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
          </button>
        </div>
      </div>
    </div>
  </section> -->




  <ng-template #jobInfo let-modal>
    <driver-info-modal [log]="selectedLog"></driver-info-modal>
  </ng-template>


