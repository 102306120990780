
  <div class="">
    <div *ngIf="!teamLeaderView" class="modal-header bg-main text-white">
      <h5 class="modal-title text-center mx-4 w-100">Secure Transport Planning: {{selectedJob.refPrefix??''+selectedJob.id}}</h5>
    </div>
    <div [class]="!teamLeaderView?'modal-body':''">
      <div class="">

        <div class="">
          <div  [class]="!teamLeaderView?'bg-modal card-body':''">
            <div class="">

              <div *ngIf="viewFullDetails">
                <section>
                    <div class="col-12">
                      <div class="  text-white">
                        <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                          Secure Transport Booking
                        </h5>
                        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </span>
                      </div>
                      <hr class="bg-black mt-0">

                      <div >
                        <h5>Collection</h5>
                      </div>
                      <div class="row form-group ">
                        <div *ngIf="userType=='client'" class="mt-1 col-sm-12 col-md-4">
                          <Label>Provider</Label>
                          <input type="text" maxlength="255"  disabled  value="{{selectedJob?.agencyName}}" class="form-control date">
                        </div>
                        <div *ngIf="userType=='agency'" class="mt-1 col-sm-12 col-md-4">
                          <Label>Client</Label>
                          <input type="text" maxlength="255"  disabled  value="{{selectedJob?.clientName}}" class="form-control date">
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Date Transport Is Required</Label>
                          <input type="text" maxlength="255"  disabled  value="{{selectedJob?.dateTimeRequired| date:'dd/MM/yyyy'}}" class="form-control date">
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Time Transport Is Required</Label>
                          <input type="text" maxlength="255"  disabled  value="{{selectedJob?.dateTimeRequired | date:'HH:mm'}}" class="form-control date">
                        </div>




                        <div *ngIf="['AUTHORIZED', 'CLOSED'].includes(selectedJob.transportStatus)" class="mt-1 col-sm-12 col-md-4">
                          <Label>Actual Start</Label>
                          <input type="text" maxlength="255"  disabled  value="{{selectedJob?.start}}" class="form-control date">
                        </div>
                        <div *ngIf="['AUTHORIZED', 'CLOSED'].includes(selectedJob.transportStatus)" class="mt-1 col-sm-12 col-md-4">
                          <Label>Actual End</Label>
                          <input type="text" maxlength="255"  disabled  value="{{selectedJob?.end}}" class="form-control date">
                        </div>
                        <div *ngIf="['AUTHORIZED', 'CLOSED'].includes(selectedJob.transportStatus)" class="mt-1 col-sm-12 col-md-4">
                          <Label>Break Time</Label>
                          <input type="text" maxlength="255"  disabled  value="{{selectedJob?.breakTime+' mins'}}" class="form-control date">
                        </div>





                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Collection Address</Label>
                          <input type="text" maxlength="255"   disabled  value="{{selectedJob?.pcaddress}}" class="form-control">
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Collection Post Code</Label>
                          <input type="text" maxlength="255"   disabled value="{{selectedJob?.pickupPostCode}}" class="form-control">
                        </div>
                        <div class="form-group col-6 col-sm-12 col-md-4">
                          <label>Collection Contact Name</label>
                          <input type="text"  maxlength="255"   disabled value="{{selectedJob?.pname}}" class="form-control" placeholder="Contact Number" >
                        </div>
                        <div class="form-group col-6 col-sm-12 col-md-4">
                          <label>Collection Email</label>
                          <input type="text"  maxlength="255"   disabled value="{{selectedJob?.pcemail}}" class="form-control" placeholder="Contact Number" >
                        </div>

                        <div class="form-group col-6 col-sm-12 col-md-4">
                          <label>Collection Ward</label>
                          <input type="text"  maxlength="255"   disabled value="{{selectedJob?.pward}}" class="form-control" placeholder="Contact Number" >
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Collection Contact</Label>
                          <input type="text" maxlength="255"   disabled value="{{selectedJob?.pickupLocationContactNumber}}" class="form-control">
                        </div>


                        <div class="col-12">
                          <h5>Destination</h5>
                        </div>

                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Destination</Label>
                          <input type="text" maxlength="255"   disabled value="{{selectedJob?.destination}}" class="form-control">
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Destination Post Code</Label>
                          <input type="text" maxlength="255"   disabled value="{{selectedJob?.destinationPostCode}}" class="form-control">
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Destination Contact Number</Label>
                          <input type="text" maxlength="255"   disabled value="{{selectedJob?.destinationContactNumber}}" class="form-control">
                        </div>






                        <div class="form-group col-6 col-sm-12 col-md-4">
                          <label>Destination Contact Person</label>
                          <input type="text"  maxlength="255"  disabled value="{{selectedJob?.dname}}" class="form-control" placeholder="Contact Number" >
                        </div>
                        <div class="form-group col-6 col-sm-12 col-md-4">
                          <label>Destination Ward</label>
                          <input type="text"  maxlength="255"  disabled value="{{selectedJob?.dward}}" class="form-control" placeholder="Contact Number" >
                        </div>
                        <div class="form-group col-6 col-sm-12 col-md-4">
                          <label>Destination Email</label>
                          <input type="text"  maxlength="255"  disabled value="{{selectedJob?.demail}}" class="form-control" placeholder="Contact Number" >
                        </div>


                      </div>

                    </div>

                </section>

                <section>
                    <div class="col-12">
                      <div class="  text-white">
                        <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                          Patient Information
                        </h5>
                        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </span>
                      </div>
                      <hr class="bg-black mt-0">

                      <div class="row form-group ">
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Name of Patient</Label>
                          <input type="text" maxlength="255"   disabled value="{{selectedJob?.patientName}}" class="form-control">
                        </div>
                        <!-- <div class="col-md-8"></div> -->

                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Gender </Label>
                          <input type="text" maxlength="255"   disabled value="{{selectedJob?.passengerGender}}" class="form-control">
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Date of Birth</Label>
                          <input type="text" maxlength="255"   disabled value="{{selectedJob?.pdob}}" class="form-control">
                        </div>

                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>NHS Number </Label>
                          <input type="text" maxlength="255"   disabled value="{{selectedJob?.nhs}}" class="form-control">
                        </div>





                        <!-- <div class="col-12"></div> -->




                        <div class="form-group col-6 col-sm-12 col-md-4">
                          <label>Diagnosis</label>
                          <input type="text"  maxlength="255" disabled value="{{selectedJob?.diagnosis??'None'}}" class="form-control" placeholder="Contact Number" >
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Reason For Transport</Label>
                          <select class="form-control" disabled value="{{selectedJob?.reasonForTransport}}">
                            <option value="MENTAL_HEALTH_ACT">Mental Health ACT</option>
                            <option value="CHILDREN_ACT">Children ACT</option>
                            <option value="INFORMAL_PATIENT">Informal Patient</option>
                          </select>
                        </div>
                        <div class="mt-1 col-12 col-md-4">
                          <Label>Has patient been administered medication within 4 hours to transfer?</Label>
                          <input type="text" disabled value="{{selectedJob?.medication??'None'}}"    class="form-control date">
                        </div>
                        <div class="mt-1 col-12 col-md-4">
                          <Label>Physical health issues or concerns</Label>
                          <input type="text" disabled value="{{selectedJob?.physicalHealth??'None'}}"   placeholder="" class="form-control date">
                        </div>

                        <div class="mt-1 col-12 col-md-4" >
                          <Label>Any specific infection control concerns?</Label>
                          <input type="text" disabled value="{{selectedJob?.infectionControl??'None'}}"   placeholder="" class="form-control date">
                        </div>
                        <div class="mt-1 col-12 col-md-4">
                          <Label>Covid-19 Status in the last 48hrs?</Label>
                          <input type="text" disabled value="{{selectedJob?.covid!=null?(selectedJob?.covid?'Positive':'Negative'):'Covid-19 Status in the last 48hrs not known'}}"   placeholder="" class="form-control date">
                        </div>

                        <div class="mt-1 col-12 col-md-4">
                          <Label>Dietary requirements?</Label>
                          <input type="text" disabled value="{{selectedJob?.offerFood??'Staff cannot offer food.'}}"   placeholder="" class="form-control date">
                        </div>

                        <div class="mt-1 col-12 col-md-4" >
                          <Label>Allergies</Label>
                          <input type="text" disabled value="{{selectedJob?.allergies??'No known allergies'}}"   placeholder="" class="form-control date">
                        </div>
                        <div class="mt-1 col-12 col-md-4">
                          <Label>Present Medication</Label>
                          <input type="text" disabled value="{{selectedJob?.pmeds??'None'}}"    class="form-control date">
                        </div>

                        <div class="mt-1 col-12 col-md-12" >
                          <Label>Has the patient received a Rapid Tranquillisation/Sedation medication in the last 6hrs before transfer??</Label>
                          <input type="text" disabled value="{{selectedJob?.rapidTranq??'None administered'}}"   placeholder="" class="form-control date">
                        </div>


                        <div class="col-12"></div>


                  <h5 class="mt-2 mb-1">Current Risks</h5>

                        <form [formGroup]="addForm" >
                          <div class="row">
                            <div class="col-md-8 col-lg-8 row ">
                              <hr class="w-100 my-1">
                              <div class="row col-12 mx-0 px-0">
                                <div class="col-6">
                                  <div class="text-bold">Risk</div>
                                </div>
                                <div class="col-6 row">
                                  <div class="col-12 text-bold">Risk Level</div>

                                  <div title="Significant" class="col-sm-2 px-1">
                                    <small >sig</small><br>
                                    <button class="badge badge-sig border-0">S</button>
                                  </div>
                                  <div title="High" class="col-sm-2 px-1">
                                    <small>high</small><br>
                                    <button class="badge badge-danger border-0">R</button>
                                  </div>
                                  <div title="Medium" class="col-sm-2 px-1">
                                    <small>med</small><br>
                                    <button class="badge badge-warning border-0">A</button>
                                  </div>
                                  <div title="Low" class="col-sm-2 px-1">
                                    <small>low</small><br>
                                    <button class="badge badge-success border-0">G</button>
                                  </div>
                                  <div title="Unkown" class="col-sm-2 px-1">
                                    <small>unkown</small><br>
                                    <button class="badge badge-default border-0">U</button>
                                  </div>



                                </div>
                              </div>

                              <hr class="w-100 my-1">
                              <div class="col-6">
                                <label class="ml-1" for=""> Assault Staff</label><br>
                                <textarea *ngIf="extremeRag.includes(addForm.value.assaultStaff)" formControlName="assaultStaffDesc" placeholder="Explanation" class="form-control">
                                </textarea>
                              </div>
                              <div class="col-6 row">
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="assaultStaff" class="sig-rag" value="S">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="assaultStaff" class="high-rag" value="H">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="assaultStaff" class="med-rag" value="M">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="assaultStaff" class="low-rag" value="L">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="assaultStaff" class="unkown-rag" value="U">
                                  </div>
                              </div>

                              <hr class="w-100 my-1">
                              <div class="col-6">
                                <label class="ml-1" for=""> Physical Aggression</label>
                                <textarea *ngIf="extremeRag.includes(addForm.value.physicalAggression)" formControlName="physicalAggressionDesc" placeholder="Explanation" class="form-control">
                                </textarea>
                              </div>
                              <div class="col-6 row">
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="physicalAggression" class="sig-rag" value="S">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="physicalAggression" class="high-rag" value="H">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="physicalAggression" class="med-rag" value="M">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="physicalAggression" class="low-rag" value="L">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="physicalAggression" class="unkown-rag" value="U">
                                  </div>
                              </div>




                              <hr class="w-100 my-1">
                              <div class="col-6">
                                <label class="ml-1" for="">Verbal aggression</label>
                                <textarea *ngIf="extremeRag.includes(addForm.value.verballyAggressive)" formControlName="verballyAggressiveDesc" placeholder="Explanation" class="form-control">
                                </textarea>
                              </div>
                              <div class="col-6 row">
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="verballyAggressive" class="sig-rag" value="S">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="verballyAggressive" class="high-rag" value="H">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="verballyAggressive" class="med-rag" value="M">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="verballyAggressive" class="low-rag" value="L">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="verballyAggressive" class="unkown-rag" value="U">
                                  </div>
                              </div>


                              <hr class="w-100 my-1">
                              <div class="col-6">
                                <label class="ml-1" for=""> Self Harm</label><br>
                                <textarea *ngIf="extremeRag.includes(addForm.value.selfHarm)" formControlName="selfHarmDesc" placeholder="Explanation" class="form-control">
                                </textarea>
                              </div>
                              <div class="col-6 row">
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="selfHarm" class="sig-rag" value="S">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="selfHarm" class="high-rag" value="H">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="selfHarm" class="med-rag" value="M">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="selfHarm" class="low-rag" value="L">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="selfHarm" class="unkown-rag" value="U">
                                  </div>
                              </div>


                              <hr class="w-100 my-1">
                              <div class="col-6">
                                <label class="ml-1" for=""> Self Neglect</label>
                                <textarea *ngIf="extremeRag.includes(addForm.value.selfNeglect)" formControlName="selfNeglectDesc" placeholder="Explanation" class="form-control">
                                </textarea>
                              </div>
                              <div class="col-6 row">
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="selfNeglect" class="sig-rag" value="S">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="selfNeglect" class="high-rag" value="H">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="selfNeglect" class="med-rag" value="M">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="selfNeglect" class="low-rag" value="L">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="selfNeglect" class="unkown-rag" value="U">
                                  </div>
                              </div>


                              <hr class="w-100 my-1">
                              <div class="col-6">
                                <label class="ml-1" for=""> Absconsion/AWOL</label>
                                <textarea *ngIf="extremeRag.includes(addForm.value.absconsionRisk)" formControlName="absconsionRiskDesc" placeholder="Explanation" class="form-control">
                                </textarea>
                              </div>
                              <div class="col-6 row">
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="absconsionRisk" class="sig-rag" value="S">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="absconsionRisk" class="high-rag" value="H">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="absconsionRisk" class="med-rag" value="M">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="absconsionRisk" class="low-rag" value="L">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="absconsionRisk" class="unkown-rag" value="U">
                                  </div>
                              </div>


                              <hr class="w-100 my-1">
                              <div class="col-6">
                                <label class="ml-1" for=""> Gender Issues</label>
                                <textarea *ngIf="extremeRag.includes(addForm.value.genderIssues)" formControlName="genderIssuesDesc" placeholder="Explanation" class="form-control">
                                </textarea>
                              </div>
                              <div class="col-6 row">
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="genderIssues" class="sig-rag" value="S">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="genderIssues" class="high-rag" value="H">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="genderIssues" class="med-rag" value="M">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="genderIssues" class="low-rag" value="L">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="genderIssues" class="unkown-rag" value="U">
                                  </div>
                              </div>


                              <hr class="w-100 my-1">
                              <div class="col-6">
                                <label class="ml-1" for=""> Racial Issues</label>
                                <textarea *ngIf="extremeRag.includes(addForm.value.racialIssues)" formControlName="racialIssuesDesc" placeholder="Explanation" class="form-control">
                                </textarea>
                              </div>
                              <div class="col-6 row">
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="racialIssues" class="sig-rag" value="S">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="racialIssues" class="high-rag" value="H">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="racialIssues" class="med-rag" value="M">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="racialIssues" class="low-rag" value="L">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="racialIssues" class="unkown-rag" value="U">
                                  </div>
                              </div>


                              <hr class="w-100 my-1">
                              <div class="col-6">
                                <label class="ml-1" for=""> Sexually Inappropriate Behaviour</label>
                                <textarea *ngIf="extremeRag.includes(addForm.value.sexuallyInappropriate)" formControlName="sexuallyInappropriateDesc" placeholder="Explanation" class="form-control">
                                </textarea>
                              </div>
                              <div class="col-6 row">
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="sexuallyInappropriate" class="sig-rag" value="S">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="sexuallyInappropriate" class="high-rag" value="H">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="sexuallyInappropriate" class="med-rag" value="M">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="sexuallyInappropriate" class="low-rag" value="L">
                                  </div>
                                  <div class="col-sm-2 px-1">
                                    <input type="radio"   formControlName="sexuallyInappropriate" class="unkown-rag" value="U">
                                  </div>
                              </div>

                            </div>
                            <div class="col-md-4 col-lg-4">
                              <div class="form-group ">
                                <div class="">
                                  <label>Can the person walk to the vehicle and step up into a vehicle unaided? </label>
                                  <label *ngIf="selectedJob?.walk" for="walk"> &nbsp;Yes</label>&nbsp;
                                  <label *ngIf="!selectedJob?.walk" for="walk"> &nbsp;No</label>
                                  <input  *ngIf="!selectedJob?.walk" type="text" maxlength="255" disabled  [value]="selectedJob?.walkInfo" class="form-control" placeholder="No explanation given">
                                </div>
                                <div class="">
                                  <label>Do you require the use of mechanical restraint/soft cuffs?</label>&nbsp;
                                  <label *ngIf="selectedJob?.passengerRequiresRestraints" for="passengerRequiresRestraints"> &nbsp;Yes</label>&nbsp;
                                  <label *ngIf="!selectedJob?.passengerRequiresRestraints" for="passengerRequiresRestraints"> &nbsp;No</label>
                                  <textarea *ngIf="selectedJob?.passengerRequiresRestraints" disabled type="text" [value]="selectedJob?.reasonsForRestrains" placeholder="No reason for restraints" class="form-control" cols="70"></textarea>
                                </div>
                                <div class="">
                                  <label>Is patient aware of the transport?&nbsp;
                                    <label *ngIf="selectedJob?.isPassengerAwareOfTransport" for="isPassengerAwareOfTransport"> &nbsp;Yes</label>&nbsp;
                                    <label *ngIf="!selectedJob?.isPassengerAwareOfTransport" for="isPassengerAwareOfTransport"> &nbsp;No</label>
                                  </label>
                                </div>
                                <div class="">
                                  <label>Total ward staff escorting the patient: </label>
                                  <input  type="text" maxlength="255" disabled  [value]="selectedJob?.wardEscort" class="form-control" placeholder="Not specified">
                                </div>

                                <p>Other risks:</p>
                                <input type="text" maxlength="255" disabled  [value]="selectedJob?.otherRisks" class="form-control" placeholder="No other risks">

                                <br><a *ngIf="selectedJob.riskDoc!=null" target="_blank" (click)="openDoc(selectedJob.riskDoc)">View Risk Document</a><br>

                                <div *ngIf="selectedJob.riskDoc!=null&&uploadDoc==false" (click)="toggleTrue(uploadDoc)" class="form-group">Change Risk Doc</div>
                                <!-- <div *ngIf="selectedJob.riskDoc==null||uploadDoc==true" class="form-group">
                                  <label for="" class="modal-label">Upload Risk Document</label><br>
                                    <input type="file" id="file" (change)="onChange($event)" accept="application/pdf">
                                </div> -->

                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                </section>
              </div>


              <section>
                <div class="text-white">
                    <h5 *ngIf="selectedJob.transportStatus!='NEW'&&userType=='agency'" class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                      Job Planner
                    </h5>
                    <h5 *ngIf="selectedJob.transportStatus=='NEW'||userType!='agency'" class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                      Staffing Requirements
                    </h5>
                </div>
                <hr class="bg-black mt-0">

                <div class="row">

                  <div  *ngIf="selectedJob.transportStatus!='NEW'&&userType=='agency' " class="col-md-6 mb-4">
                    <div *ngFor="let r of selectedJob?.transportWorkerSpecList" class="row mb-4">
                      <div class="col-5">
                        <b>Job Description</b>
                      </div>
                      <!-- <div class="col-4"><b>Gender</b></div> -->
                      <div class="col-3"><b>Total   Staff</b></div>

                        <div class="col-5">
                          <input type="text" disabled maxlength="255" class="w-100 form-control" value="{{r.assignmentCodeName}}">
                        </div>
                        <!-- <div class="col-4">
                          <input type="text" disabled maxlength="255" class="w-100 form-control" [value]="r.gender=='NO_PREFERENCE'?'No Preference':r.gender ">
                        </div> -->
                        <div class="col-3">
                          <input type="text" disabled maxlength="255" class="w-100 form-control" value="{{r.numberOfStaff}}">
                        </div>


                          <div class="col-12">Workers:</div>

                          <div *ngFor="let b of r.bookings;  index as i" class="col-12  ">
                            <div *ngIf="b.shiftStatus!='APPLIED'&&b.shiftStatus !='CANCELLED'" class="col-12 col-sm-12 col-md-12 text-left mx-0 mb-1 px-0 w-100 row">
                              <div class="col-7 mx-0 px-0">
                                <input  class="form-control" disabled [value]="b.worker">
                              </div>
                                <div *ngIf="selectedJob.transportStatus=='BOOKED'||selectedJob.transportStatus=='PENDING'" class="col-5 d-flex pr- mr-0">
                                  <button  class="btn ml-auto badge mr-1 btn-danger" (click)="removeBooking(b.id)" >
                                    Remove
                                  </button>
                                </div>
                            </div>
                            <div *ngIf="b.shiftStatus=='CANCELLED'" class="col-12 col-sm-12 col-md-12 text-left mx-0 mb-1 px-0 w-100 row">
                              <div class="col-7 mx-0 px-0">
                                <input  class="form-control text-danger" disabled [value]="b.worker+' (CANCELLED)'">
                              </div>
                            </div>
                          </div>

                          <div *ngFor="let _ of [].constructor(getBookingsArrayzz(r));  index as i" class="col-12 mb-1 ">
                            <div class="col-12 col-sm-12 col-md-12 text-left mx-0 px-0 w-100">
                              <input class="form-control" id="numberOfShifts{{ i }}" value="1" type="hidden">
                              <select class="form-control" id="worker{{ i }}" (change)="bookWorker(r.id,$event)"  style="min-width: 200px;" required>
                                <option value="" selected class="w-100" disabled >Select A Worker</option>
                                <option *ngIf=" getSpecWorkers(r.id).length<1"  class="w-100" disabled >No Legible Workers</option>
                                  <option *ngFor="let d of getSpecWorkers(r.id)"  value="{{d.workerId}}" >
                                    {{ d.workerName }} {{d.available?'':' (UNAVAILABLE)'}}
                                  </option>
                              </select>
                            </div>
                          </div>


                          <div class="col-12">{{r.bookings?.length>0?'Applicants:':'No Applicants'}}</div>
                          <div *ngFor="let b of r.bookings;  index as i" class="col-12  ">
                            <div *ngIf="b.shiftStatus=='APPLIED'" class="col-12 col-sm-12 col-md-12 text-left mx-0 mb-1 px-0 w-100 ">
                              <div class=" d-flex">
                                <input  class="form-control mr-1" disabled [value]="b.worker">
                                  <button  class="btn ml-auto badge mr-1 btn-danger" (click)="rejectJobApplication(b.id)" >
                                    Reject
                                  </button>
                                  <button  class="btn ml-auto badge mr-1 btn-success" (click)="approveJobApplication(b.id)" >
                                    Approve
                                  </button>
                                </div>
                            </div>
                          </div>



                    </div>
                  </div>

                  <div  *ngIf="selectedJob.transportStatus=='NEW'||userType!='agency'" class="col-md-12">
                    <div *ngFor="let r of selectedJob?.transportWorkerSpecList" class="row mb-3">
                      <div class="col-5">
                        <b>Job Description</b>
                      </div>
                      <!-- <div class="col-4"><b>Gender</b></div> -->
                      <div class="col-3"><b>Total   Staff</b></div>
                      <div class="col-5">
                        <input type="text" disabled maxlength="255" class="form-control" value="{{r.assignmentCodeName}}">
                      </div>
<!--
                      <div class="col-4 mx-0 px-0">
                        <input type="text" disabled maxlength="255" class="form-control" [value]="r.gender=='NO_PREFERENCE'?'No Preference':r.gender ">
                      </div> -->
                      <div class="col-3">
                        <input type="text" disabled maxlength="255" class="form-control" value="{{r.numberOfStaff}}">
                      </div>
                    </div>
                  </div>


                    <div *ngIf="selectedJob.transportStatus!='NEW'||userType!='agency'" class="col-md-6">
                      <div >Vehicle:</div>
                      <div  class="mb-1 ">
                        <div class="text-left ">
                          <select  *ngIf="['BOOKED','AWAITING_AUTHORIZATION','AUTHORIZED', 'PENDING'].includes(selectedJob.transportStatus)" class="form-control"   (change)="pickVehicle($event)"  style="min-width: 200px;" required >
                            <option value="" class="w-100" selected [disabled]="teamLeaderView">Select A Vehicle</option>
                              <option *ngFor="let d of vehicles"  [selected]="selectedJob?.vehicle?.id==d.id" value="{{d.id}}" >
                                {{ d.name}} {{d.description}} {{ d.regno }}
                              </option>
                          </select>
                          <input  *ngIf="!['BOOKED','AWAITING_AUTHORIZATION','AUTHORIZED', 'PENDING'].includes(selectedJob.transportStatus)" class="form-control"  disabled  style="min-width: 200px;" required [value]="selectedJob.vehicle?.name+' '+selectedJob.vehicle?.regno">
                        </div>
                      </div>

                      <div  >Team Leader:</div>
                      <div  class="mb-1 ">
                        <div class="text-left ">
                          <select  *ngIf="['BOOKED','AWAITING_AUTHORIZATION','AUTHORIZED', 'PENDING'].includes(selectedJob.transportStatus)" class="form-control"   (change)="pickTeamLeader($event)"  style="min-width: 200px;" required  >
                            <option value="" selected class="w-100" [disabled]="teamLeaderView" >Select Leader</option>
                              <option *ngFor="let d of bookedWorkers" [selected]="selectedJob?.teamLeader?.id==d.workerId"  value="{{d.workerId}}" >
                                {{ d.worker}}
                              </option>
                          </select>
                          <input  *ngIf="!['BOOKED','AWAITING_AUTHORIZATION','AUTHORIZED', 'PENDING'].includes(selectedJob.transportStatus)" class="form-control"  disabled  style="min-width: 200px;" required [value]="selectedJob.teamLeader?.firstname+' '+selectedJob.teamLeader?.lastname">
                        </div>
                      </div>
                    </div>
                </div>
              </section>


              <section *ngIf="(selectedJob.transportStatus!='NEW')&&!teamLeaderView">
                <div class="col-12">
                  <div class=" row text-white">
                    <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                      Submitter Details
                    </h5>

                  </div>
                  <hr class="bg-black mt-0">

                  <div class=" form-group row ">


                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Business Name</Label>
                      <input type="text" disabled value="{{selectedJob?.bname}}"   placeholder="" class="form-control date">
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Form submitted by</Label>
                      <input type="text" disabled value="{{selectedJob?.submittedBy}}"   placeholder="" class="form-control date">
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Submitter Email</Label>
                      <input type="text" disabled value="{{selectedJob?.semail}}"   placeholder="" class="form-control date">
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Submitter Phone</Label>
                      <input type="text" disabled value="{{selectedJob?.sphone}}"   placeholder="" class="form-control date">
                    </div>
                    </div>
                  </div>
                </div>




                <div *ngIf="selectedJob.transportStatus=='NEW' && userType=='agency' " class="form-group text-right col-12 col-sm-12 col-md-12">
                  <button class="btn btn-success btn-sm mr-3" (click)="openModal(acceptModal)">
                    Accept
                  </button>
                </div>



                <div *ngIf="selectedJob.transportStatus=='BOOKED'" class="form-group col-12 col-sm-12 col-md-12">
                  <div class="mt-4" align="right" >
                    <button type="button" (click)="openModal(editJobModal)" class="btn btn-success  mx-2" >Edit</button>
                    <button type="button" (click)="commitJob()" class="btn btn-success  mx-2" >Submit & Notify Workers</button>
                    <button *ngIf="selectedJob.transportStatus=='AWAITING_AUTHORIZATION'" type="button" (click)="openModal(authModal)" class="btn btn-success  mx-2" >Authorize Job</button>

                  </div>
                </div>


                <div *ngIf="selectedJob.transportStatus=='WAITING'" class="form-group col-12 col-sm-12 col-md-12">
                  <div class="mt-4" align="right" >
                    <button type="button" (click)="openModal(editJobModal)" class="btn btn-success  mx-2" >Edit</button>
                    <button type="button" (click)="openModal(authModal)" class="btn btn-success  mx-2" >Authorize Job</button>
                  </div>
                </div>

                <div *ngIf="selectedJob.transportStatus=='PENDING'" class="form-group col-12 col-sm-12 col-md-12">
                  <div class="mt-4" align="right" >
                    <button type="button" (click)="openModal(editJobModal)" class="btn btn-success  mx-2" >Edit</button>
                    <button type="button" class="btn btn-danger btn-sm mr-3" (click)="closeModals()" >
                      BACK
                    </button>
                  </div>
                </div>

                <div *ngIf="selectedJob.transportStatus=='AWAITING_AUTHORIZATION'" class="form-group col-12 col-sm-12 col-md-12">
                  <div class="mt-4" align="right" >

                    <button type="button" class="btn btn-danger btn-sm mr-3" (click)="closeModals()" >
                      BACK
                    </button>
                    <button type="button" (click)="openModal(editJobModal)" class="btn btn-success  mx-2" >Edit</button>
                    <button type="button" (click)="openModal(authModal)" class="btn btn-success  mx-2" >Authorize Job</button>
                  </div>
                </div>

                <div *ngIf="selectedJob.transportStatus=='AUTHORIZED'" class="form-group col-12 col-sm-12 col-md-12">
                  <div class="mt-4" align="right" >
                    <button type="button" class="btn btn-danger btn-sm mr-3" (click)="closeModals()" >
                      BACK
                    </button>
                    <button type="button" (click)="openModal(editJobModal)" class="btn btn-success  mx-2" >Edit</button>
                    <button  type="button" (click)="openModal(authModal)" class="btn btn-success  mx-2" >Authorize Job</button>

                  </div>
                </div>

              </section>
              <section *ngIf="selectedJob.transportStatus=='NEW'">
                <div class="col-12">
                  <div class=" row text-white">
                    <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                      Financial Details
                    </h5>

                  </div>
                  <hr class="bg-black mt-0">

                  <div class=" form-group row ">


                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Purchase Order Number</Label>
                      <input type="text" disabled value="{{selectedJob?.porderNum}}"   placeholder="" class="form-control date">
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>SBS Code</Label>
                      <input type="text" disabled value="{{selectedJob?.sbsCode}}"   placeholder="" class="form-control date">
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Business Name</Label>
                      <input type="text" disabled value="{{selectedJob?.bname}}"   placeholder="" class="form-control date">
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Business Address</Label>
                      <input type="text" disabled value="{{selectedJob?.baddress}}"   placeholder="" class="form-control date">
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Billing Invoice Email</Label>
                      <input type="text" disabled value="{{selectedJob?.bemail}}"   placeholder="" class="form-control date">
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Form submitted by</Label>
                      <input type="text" disabled value="{{selectedJob?.submittedBy}}"   placeholder="" class="form-control date">
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Submitter Email</Label>
                      <input type="text" disabled value="{{selectedJob?.semail}}"   placeholder="" class="form-control date">
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="mt-1 col-12">
                      <Label>Submitter Phone</Label>
                      <input type="text" disabled value="{{selectedJob?.sphone}}"   placeholder="" class="form-control date">
                    </div>
                    </div>
                  </div>
                </div>




                <div *ngIf="selectedJob.transportStatus=='NEW' && userType=='agency' " class="form-group text-right col-12 col-sm-12 col-md-12">
                  <button class="btn btn-success btn-sm mr-3" (click)="openModal(acceptModal)">
                    Accept
                  </button>
                </div>






              </section>



              <!-- View Full Job Details -->
              <a *ngIf="(selectedJob.transportStatus!='NEW')&&!teamLeaderView" class=" m-1" (click)="toggleView()">
                <i  class='fas fa-eye'></i>&nbsp;&nbsp;{{viewFullDetails?"Show Less":"View Full Job Details"}}
              </a>
            </div>
          </div>
        </div>
      </div>


      <script>
        function maxLength(el) {
          if (!('maxLength' in el)) {
              var max = el.attributes.maxLength.value;
              el.onkeypress = function () {
                  if (this.value.length >= max) return false;
              };
          }
        }

        maxLength(document.getElementById("text"));
      </script>

    </div>
  </div>


  <!-- Modals -->

  <ng-template class="modal fade" #acceptModal let-modal>
    <div class="">
        <div class="modal-header bg-main text-white">
            <h5 class="modal-title text-center w-100">Accept Job</h5>
            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </span>
        </div>
        <div class="modal-body">
            <div class="bg-modal card-body">
                <div class="text-center">
                    <h3>You are about to accept this New Job.</h3>
                </div>
            </div>
            <div align="right" class="mt-3">
              <button class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                  Cancel
              </button>
              <button class="btn btn-sm btn-success mr-3" (click)="acceptJob(false)">
                  Accept Joba
              </button>
              <button class="btn btn-sm btn-success" (click)="acceptJob(true)">
                  Accept Job & Save New Client
              </button>
            </div>
        </div>
    </div>
  </ng-template>


  <!-- <ng-template class="modal fade" #acceptAndSave let-modal>
    <div class="">
        <div class="modal-header bg-main text-white">
            <h5 class="modal-title text-center w-100">Accept Job</h5>
            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </span>
        </div>
        <div class="modal-body">
            <div class="bg-modal card-body">
                <div class="text-center">
                    <h3>Would you like to accept this job and save the details into the system</h3>
                </div>
            </div>
            <div align="right" class="mt-3">
              <button class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                  Just the job
              </button>
              <button *ngIf="selectedJob.clientId==null" class="btn btn-sm btn-success" (click)="onSubmit();toggleView()">
                  Accept and Save client details
              </button>
            </div>
        </div>
    </div>
  </ng-template> -->



<ng-template class="modal fade" #authModal let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center w-100">Authorize Transport Job</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">

        <form [formGroup]="authorizeForm" (ngSubmit)="authorizeJob(authorizeForm)">
          <div class="bg-modal card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <p>This action will authorise this job and workers involved</p>
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Job Id: {{selectedJob.id}}</span>
                  <hr class="bg-black mt-0">
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <Label>Date Transport Is Required</Label>
                    <input type="text" maxlength="255"  disabled  value="{{selectedJob?.dateTimeRequired| date:'dd/MM/yyyy'}}" class="form-control date">
                    <Label>Time Transport Is Required</Label>
                    <input type="text" maxlength="255"  disabled  value="{{selectedJob?.dateTimeRequired| date:'HH:mm'}}" class="form-control date">
                  </div>
                  <div class="form-group">
                    <input type="number" hidden maxlength="255" formControlName="transportId" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="start">Actual Start Date and Time</label>
                    <input type="datetime-local"  maxlength="255" formControlName="start" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="end">Actual End Date and Time</label>
                    <input type="datetime-local"  maxlength="255" formControlName="end" class="form-control">
                  </div>
                  <div class="form-group">
                    <label>Break Time</label>
                    <input type="number" maxlength="255" formControlName="breakTime" placeholder="minutes" class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="right" class="mt-3">
            <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-success">
                Authorize
            </button>
          </div>
        </form>
      </div>
  </div>
  </ng-template>


  <ng-template class="modal fade" #editJobModal let-modal>
    <div class="card">
      <app-create-transport-job [selectedJob]  =  "selectedJob"></app-create-transport-job>
    </div>
  </ng-template>
