import { Component, EventEmitter,OnInit, Input, OnChanges, Output } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { FormArray, Validators } from '@angular/forms';
import { AssetService } from 'src/app/shared/services/asset.service';
import { ShiftService } from 'src/app/shared/services/shift.service';
import { AuthService } from 'src/app/shared/services/auth.service';


  @Component({
    selector: 'app-rates-tab',
    templateUrl: './rates-tab.component.html',
    styleUrls: ['./rates-tab.component.css']
  })



  export class RatesTabComponent implements OnInit {
    @Input('form') form: FormGroup;
    @Input('job') job: any;
    @Input('tabindex') tabindex: string; // Ensure tabindex is initialized
    @Output() formO = new EventEmitter<FormGroup>();
    @Output() tabindexO = new EventEmitter<string>();

    vehicles: any;
    locations: any;
    selectedDirectorate: any;

    locationFilter:number = null;


    editForm: FormGroup;

    page = 1;
    pageSize = 20;
    totalItems = 0;
    showin = 0;
    first: boolean;
    last: boolean;

    userType: string;
    clients: any;
    searchCriteria: string = null;
rateType = "D";

    constructor(
      private shiftsService: ShiftService,
      private toast: ToastrService,
      private modalService: NgbModal,
      private assetService: AssetService,
      private agencyService: AgencyService,
      private transportService: TransportService,
      private storageService: StorageService,
      private fb: FormBuilder,
      private authService: AuthService
    ) {
      this.userType = authService.getUserType();
     }

    ngOnInit(): void {
      this.getVehicles();

      this.editForm = this.fb.group({
        id: 0
      });

    }


    enableEdit() {
      this.form.enable();
    }

    disableForm() {
      this.form.disable();
    }


    track(item: any, index: number) {
      return index;
    }

    get ratesArray() {
      return <FormArray>this.form.get('vehicleRates');
    }

    handlePageChange(event) {

      if(event == 'next'){
        this.page += 1;
      }
      else if(event == 'prev' && this.page != 1){
        this.page -= 1;
      }

      this.getVehicles();
    }

    getVehicles(){
      this.assetService.getAgencyAssets(this.storageService.decrypt(localStorage.getItem('agentId')),'VEHICLE',this.searchCriteria, this.page - 1, this.pageSize).subscribe(
        data => {
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }

          let array = data.content;
          array.sort(function(a, b){
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
          })

          this.vehicles = array;
        }
      );
    }

    getLocations(){
      this.shiftsService.getPaginatedShiftLocations(this.page - 1, 1000).subscribe(
        data => {
          this.locations = data.content;
          console.log(this.locations);
        }
      );
    }

    openModal(modal) {
      this.modalService.open(modal, {centered: true});
    }

    deleteDirectorate(){

      if(this.selectedDirectorate.id){
        this.shiftsService.deleteShiftDirectorate(this.selectedDirectorate.id).subscribe(
          resp => {
            // console.table(resp);
            this.modalService.dismissAll();
            this.getVehicles();
            this.toast.success('Directorate Deleted Successfully');
          }
        );
      }
      else{
        this.toast.warning('Missing Required Info');
      }
    }

    updateVehicle() {
      this.form.patchValue({
        agency: {id: this.form.value.agency.id }
      })
      this.transportService.updateVehicleRates(this.form.value).subscribe(
        data => { this.toast.success("Info updated");
          this.disableForm();
        }
      );
    }

    updateDirectorate(form: FormGroup){
      console.log(form.value);

      if(form.valid){
        this.shiftsService.updateShiftDirectorate(form.value).subscribe(
          resp => {
            // console.table(resp);
            this.modalService.dismissAll();
            this.getVehicles();
            this.toast.success('Directorate Updated Successfully');
          }
        );
      }
      else{
        this.toast.warning('Missing Required Info');
      }
    }

    loadDirectorate(ref, modal, edit){
      if(ref){
        this.vehicles.forEach(r => {
          if(r.id == ref){
            this.selectedDirectorate = r;
          }
        });

        if(edit && this.selectedDirectorate){
          this.editForm = this.fb.group({
            id: [this.selectedDirectorate.id, Validators.required],
            locationId: [this.getLocationId(this.selectedDirectorate.location), Validators.required],
            name: [this.selectedDirectorate.name, Validators.required],
            postCode: [this.selectedDirectorate.postCode, Validators.required],
            phoneNumber: [this.selectedDirectorate.phoneNumber, Validators.required],
          });
        }
      }

      if(modal){
        this.openModal(modal);
      }
    }

    getLocationId(ref){
      let lid;

      if(ref){
        this.locations?.forEach(r => {
          if(r.name == ref){
            lid = r.id;
          }
        });
      }

      return lid;
    }

  }


