import { AgencyService } from './../../../shared/services/agency.service';
import { ClientService } from './../../../shared/services/client.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Router } from '@angular/router';
import { StatisticsService } from 'src/app/shared/services/statistics.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agency-jobs-list',
  templateUrl: './agency-logs-list.component.html',
  styleUrls: ['./agency-logs-list.component.css']
})
export class AgencyLogsListComponent implements OnInit {
  agencyFilter  : string= "";
  clientFilter  : string= "";
  startDateFilter: string= "";
  endDateFilter: string= "";
  searchCriteria: string= "";

  selectedTab: string = 'NEW';
  clients: any;

  page: number =1;
  pageSize = 25;
  totalItems = 0;
  showin = 0;
  first: boolean;
  updated: boolean = false;
  last: boolean;
  shiftsSummary: any;
  userType: string;
  link = environment.baseUrl+'/guest/transport/request/'+localStorage.getItem('agentId')+'#guest-land';

  constructor(
    private toast : ToastrService,
    private clientService : ClientService,
    private agencyService : AgencyService,
    private router: Router,
    private statisticsService: StatisticsService,
    private authService: AuthService,
    private storageService: StorageService,
    private modalService: NgbModal

  ) {this.userType = authService.getUserType(); }

  ngOnInit(): void {
    // this.getClients();
    this.getStats();
  }


  selectTab(ref) {
      this.selectedTab = ref;
      this.getStats();
  }

  refreshPage(changes): void {
    console.log("Transport List Updated");
    this.ngOnInit();
  }

  async reloadTab() {
    var ref = this.selectedTab.toString();
    this.selectedTab = "";
    await this.delay(1);
    if(ref){
      console.log("tab change");
      this.selectedTab = ref;
    }
  }

   delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
    }



  openModal(modal: any){
    this.modalService.open(modal, {centered: true});
  }

  clearFilters(){
    this.agencyFilter     = "";
    this.clientFilter     = "";
    this.startDateFilter   = "";
    this.endDateFilter   = "";
    this.searchCriteria   = "";
  }


  newPage(){
    this.router.navigate(['/agency/jobs/new']);
  }


  getStats(){
     this.statisticsService.getAgencyTransportLogsShiftsDashboard( this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
      data => {
         this.shiftsSummary = data;
          console.log(this.shiftsSummary)

       }
     );
}

copyMessage(val: string){
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  this.toast.info("Copied the text: " + val);
}



}
